import React, { useState } from "react";
import { crearCargosOpenpay } from "../../api/openPay";

function OpenpayTransferencias() {
  const [paymentData, setPaymentData] = useState({
    customer_id: "aswrjptj8ih4othmcthl", // Reemplaza con el ID del cliente real
    customer_name: "John Doe", // Asegúrate de incluir el nombre del cliente
    customer_email: "john.doe@example.com", // Asegúrate de incluir el email del cliente
    amount: 12.50,
    description: "Transferencia entre cuentas",
    order_id: "oid-1245678"
  });

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setPaymentData({ ...paymentData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    try {

      const dataTemp = {
        method: 'bank_account',
        amount: paymentData.amount,
        description: paymentData.description,
        order_id: paymentData.order_id,
        customer: {
          id: paymentData.customer_id,
          name: paymentData.customer_name,
          email: paymentData.customer_email,
        },
      }

      const response = await crearCargosOpenpay(dataTemp);

      console.log("Transferencia realizada con éxito:", response);
      // Lógica para manejar el éxito, como limpiar el formulario o redirigir
    } catch (err) {
      console.error("Error al realizar la transferencia:", err);
      setError("Hubo un error al realizar la transferencia.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <input
        type="number"
        name="amount"
        placeholder="Monto"
        value={paymentData.amount}
        onChange={handleChange}
      />
      <input
        type="text"
        name="description"
        placeholder="Descripción"
        value={paymentData.description}
        onChange={handleChange}
      />
      <input
        type="text"
        name="customer_name"
        placeholder="Nombre del Cliente"
        value={paymentData.customer_name}
        onChange={handleChange}
      />
      <input
        type="email"
        name="customer_email"
        placeholder="Email del Cliente"
        value={paymentData.customer_email}
        onChange={handleChange}
      />
      {error && <p style={{ color: 'red' }}>{error}</p>}
      <button type="submit" disabled={loading}>
        {loading ? "Procesando..." : "Realizar transferencia"}
      </button>
    </form>
  );
}

export default OpenpayTransferencias;
