import { API_CONVERSION } from "../utils/constants";
import {
    ENDPOINTObtenerTasaCambio
} from "./endpoints";
import axios from 'axios';

export async function obtenerTasaCambio(currency) {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
    };
    try {
        const response = await axios.get(API_CONVERSION + ENDPOINTObtenerTasaCambio, config);
        const tasaCambio = response.data.conversion_rates[currency.toUpperCase()];
        if (!tasaCambio) {
            throw new Error('Moneda no soportada');
        }
        return tasaCambio;
    } catch (error) {
        console.error('Error al obtener la tasa de cambio:', error);
        throw error;
    }
}