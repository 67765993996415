import {
  faCashRegister,
  faDollarSign,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Button, Col, Form, InputGroup, Row } from "react-bootstrap";
import { listarEstablecimientosPorCorreo } from "../../../api/establecimientos";
import { registraPagos } from "../../../api/pagos";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { obtenerUsuario } from "../../../api/usuarios";
import { getTokenApi, obtenidusuarioLogueado } from "../../../api/auth";
import {
  actualizarSaldosCliente,
  actualizarSaldosCorreo,
  registraSaldosCliente,
} from "../../../api/saldosCliente";
import { LogsInformativos } from "../../logs/logs";
import { obtenerTasaCambio } from "../../../api/conversionMoneda";
import { crearCargosOpenpay, crearTarjetaOpenpay } from "../../../api/openPay";
import { obtenerDoctoresPorCorreo } from "../../../api/doctores";

const PagosClientes = ({ data, saldoPagado, saldoPendiente, pagosOrdenes, setShow }) => {
  const generateOrderId = () => {
    const timestamp = new Date().getTime();
    const randomString = Math.random().toString(36).substring(2, 15);
    return `${timestamp}-${randomString}`;
  };

  console.log(data);
  const [concepto, setConcepto] = useState("");
  const [cargo, setCargo] = useState("");
  const [formaPago, setFormaPago] = useState("efectivo");
  const [currency, setCurrency] = useState("MXN");
  const [tasaCambioMXN, setTasaCambioMXN] = useState(null);

  const [numeroTarjeta, setNumeroTarjeta] = useState('');
  const [mesExpiracion, setMesExpiracion] = useState('');
  const [anoExpiracion, setAnoExpiracion] = useState('');
  const [cvv, setCvv] = useState('');

  const createCard = async (cardData) => {
    try {
      const newCard = await crearTarjetaOpenpay(cardData);
      console.log("Tarjeta creada:", newCard);
      return newCard;
    } catch (error) {
      console.error('Error al crear la tarjeta:', error);
      throw error; // Re-throw error to be caught in handleSubmit
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const tasa = await obtenerTasaCambio("MXN");
        setTasaCambioMXN((Math.ceil(tasa) - 2));
        console.log(`1 USD = ${tasa} MXN`);
      } catch (error) {
        console.error("Error al obtener la tasa de cambio:", error);
      }
    };

    if (formaPago === "efectivo" && currency === "USD") {
      fetchData();
    }
  }, [formaPago, currency]);

  const nombreCliente =
    data.nombre + " " + data.apellidoPaterno + " " + data.apellidoMaterno;

  const [correoUsuario, setCorreoUsuario] = useState("");

  const obtenerDatosUsuario = () => {
    try {
      obtenerUsuario(obtenidusuarioLogueado(getTokenApi()))
        .then((response) => {
          const { data } = response;
          setCorreoUsuario(data.email);
        })
        .catch((e) => {
          if (e.message === "Network Error") {
            //console.log("No hay internet")
            toast.error("Conexión al servidor no disponible");
          }
        });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    obtenerDatosUsuario();
  }, []);

  const [telefonoDoctor, setTelefonoDoctor] = useState("");

  const obtenerDatosDoctor = () => {
    try {
      obtenerDoctoresPorCorreo(data.email)
        .then((response) => {
          const { data } = response;
          setTelefonoDoctor(data.telefonoCelular);
        })
        .catch((e) => {
          if (e.message === "Network Error") {
            //console.log("No hay internet")
            toast.error("Conexión al servidor no disponible");
          }
        });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    obtenerDatosDoctor();
  }, [data.email]);

  const registrarSaldoCliente = () => {
    try {
      const dataTemp = {
        nombreCliente: nombreCliente,
        correoCliente: data.email,
        saldo: cargo,
        estado: "activo",
      };
      registraSaldosCliente(dataTemp).then((response) => {
        const { data } = response;
        toast.success(data.mensaje);
        setShow(false);
      });
    } catch (e) {
      console.log(e);
    }
  };

  const actualizaSaldo = () => {
    try {
      const correo = data.email;
      let nuevoSaldo = parseFloat(saldoPagado) + parseFloat(cargo);
      const dataTemp = {
        saldo: nuevoSaldo,
      };
      actualizarSaldosCorreo(correo, dataTemp).then((response) => {
        const { data } = response;
        //LogsInformativos("Se ha registrado el pago del cliente con correo " + correo, dataTemp)
        toast.success(data.mensaje);
        setShow(false);
      });
    } catch (e) {
      console.log(e);
    }
  };

  let fecha = new Date();

  // Obtener el día, mes y año
  let dia = fecha.getDate();
  let mes = fecha.getMonth() + 1; // Sumar 1 para obtener el mes en formato 1-12
  let year = fecha.getFullYear();
  let hora = fecha.getHours(); // Obtener la hora (formato de 0 a 23)
  let minutos = fecha.getMinutes(); // Obtener los minutos
  let fechaFormat = `${year}-${mes < 10 ? ("0" + mes) : mes}-${dia < 10 ? ("0" + dia) : dia}`;

  const registrarPagoCliente = async () => {
    try {
      const verifica = saldoPagado;
      let cantidad = parseFloat(cargo);
      if (formaPago === "efectivo" && currency === "USD") {
        cantidad *= tasaCambioMXN;
      }
      const dataTemp2 = {
        correoCliente: data.email,
        nombreCliente: nombreCliente,
        concepto: concepto,
        cantidad: (formaPago === "efectivo" && currency === "USD") ? cantidad : cargo,
        formaPago: formaPago,
        realizaOperacion: correoUsuario,
        fecha: fechaFormat,
        estado: "activo",
      };

      const dataTemp = {
        customer: {
          name: data.nombre,
          last_name: data.apellidoPaterno + " " + data.apellidoMaterno,
          phone_number: telefonoDoctor,
          email: data.email
        },
        order_id: generateOrderId(),
        amount: Number(cargo), // Si cargo es $10.00, amount debería ser 1000
        method: "bank_account",
        due_date: new Date(Date.now() + 24 * 60 * 60 * 1000).toISOString(), // Set due date to 24 hours in the future
        description: concepto,
      }

      if (formaPago === "transferencia") {

        const nuevoCargo = await crearCargosOpenpay(dataTemp);

        if (nuevoCargo.status === 'in_progress') {
          console.log('El pago está en progreso:', nuevoCargo);

          const paymentDetails = nuevoCargo.payment_method;
          if (paymentDetails) {
            // Provide user with details or a link to continue with payment
            window.open(paymentDetails.url_spei);

          } else {
            console.error('No se encontraron detalles del método de pago:', nuevoCargo);
          }
        } else if (nuevoCargo.status === 'completed') {
          console.log('Pago completado:', nuevoCargo);
          // Handle completed payment
        } else {
          console.error('Estado del pago no manejado:', nuevoCargo);
        }
      } else if (formaPago == "tarjeta") {
        const cardData = {
          card_number: numeroTarjeta,
          holder_name: data.nombre + " " + data.apellidoPaterno + " " + data.apellidoMaterno,
          expiration_month: mesExpiracion,
          expiration_year: anoExpiracion,
          cvv2: cvv,
          device_session_id: "'kR1MiQhz2otdIuUlQkbEyitIqVMiI16f'",
        };

        const newCard = await createCard(cardData);
        if (!newCard || !newCard.id) {
          throw new Error("No se recibió un ID de tarjeta válido.");
        }

        const chargeRequest = {
          source_id: newCard.id,
          method: "card",
          currency: "MXN",
          description: concepto,
          order_id: generateOrderId(),
          device_session_id: 'kR1MiQhz2otdIuUlQkbEyitIqVMiI16f',
          amount: Number(cargo),
          customer: {
            name: data.nombre,
            last_name: data.apellidoPaterno + " " + data.apellidoMaterno,
            phone_number: telefonoDoctor,
            email: data.email
          },
        };

        const chargeResponse = await crearCargosOpenpay(chargeRequest);

        console.log('Cargo creado:', chargeResponse);
      }

      registraPagos(dataTemp2).then((response) => {
        const { data } = response;

        if (verifica > 0) {
          actualizaSaldo();
        } else {
          registrarSaldoCliente();
        }
        toast.success(data.mensaje);
      });
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div>
      <Form>
        <Row className="mb-2 mb-md-4 mb-lg-7">
          <Col
            sm={12}
            md={4}
            lg={4}
            className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
          >
            <Form.Label>
              Cliente: <code>*</code>
            </Form.Label>
          </Col>
          <Col
            sm={12}
            md={8}
            lg={8}
            className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
          >
            <Form.Control type="text" value={data.email} disabled />
          </Col>
        </Row>
        <Row className="mb-2 mb-md-4 mb-lg-7">
          <Col
            sm={12}
            md={4}
            lg={4}
            className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
          >
            <Form.Label>
              Razon Social: <code>*</code>
            </Form.Label>
          </Col>
          <Col
            sm={12}
            md={8}
            lg={8}
            className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
          >
            <Form.Control type="text" value={nombreCliente} disabled />
          </Col>
        </Row>
        <Row className="mb-2 mb-md-4 mb-lg-7">
          <Col
            sm={12}
            md={4}
            lg={4}
            className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
          >
            <Form.Label>Saldo pagado:</Form.Label>
          </Col>
          <Col
            sm={12}
            md={8}
            lg={8}
            className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
          >
            <InputGroup>
              <InputGroup.Text>$</InputGroup.Text>
              <Form.Control value={saldoPagado} type="number" disabled />
            </InputGroup>
          </Col>
        </Row>
        <Row className="mb-2 mb-md-4 mb-lg-7">
          <Col
            sm={12}
            md={4}
            lg={4}
            className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
          >
            <Form.Label>Saldo pendiente:</Form.Label>
          </Col>
          <Col
            sm={12}
            md={8}
            lg={8}
            className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
          >
            <InputGroup>
              <InputGroup.Text>$</InputGroup.Text>
              <Form.Control value={parseFloat(saldoPendiente)} type="number" disabled />
            </InputGroup>
          </Col>
        </Row>
        <Row className="mb-2 mb-md-4 mb-lg-7">
          <Col
            sm={12}
            md={4}
            lg={4}
            className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
          >
            <Form.Label>
              Forma de pago: <code>*</code>
            </Form.Label>
          </Col>
          <Col
            sm={12}
            md={8}
            lg={8}
            className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
          >
            <Form.Control
              type="text"
              as="select"
              value={formaPago}
              onChange={(e) => setFormaPago(e.target.value)}
              placeholder={"Ingresa forma de pago"}
            >
              <option value="efectivo">Efectivo</option>
              <option value="tarjeta">Tarjeta</option>
              <option value="transferencia">Transferencia</option>
              <option value="cheques">Cheques</option>
              <option value="depositos">Depositos</option>
            </Form.Control>
          </Col>
        </Row>
        {formaPago === "efectivo" && (
          <Row className="mb-2 mb-md-4 mb-lg-7">
            <Col
              sm={12}
              md={4}
              lg={4}
              className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
            >
              <Form.Label>
                Moneda: <code>*</code>
              </Form.Label>
            </Col>
            <Col
              sm={12}
              md={8}
              lg={8}
              className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
            >
              <Form.Control
                type="text"
                as="select"
                value={currency}
                onChange={(e) => setCurrency(e.target.value)}
                placeholder={"Selecciona la moneda"}
              >
                <option value="MXN">Pesos Mexicanos</option>
                <option value="USD">Dólares</option>
              </Form.Control>
            </Col>
          </Row>
        )}
        {formaPago === "tarjeta" && (
          <>
            <Row className="mb-2 mb-md-4 mb-lg-7">
              <Col
                sm={12}
                md={4}
                lg={4}
                className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
              >
                <Form.Label>
                  Numero de tarjeta: <code>*</code>
                </Form.Label>
              </Col>
              <Col
                sm={12}
                md={8}
                lg={8}
                className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
              >
                <Form.Control
                  type="Number"
                  value={numeroTarjeta}
                  onChange={(e) => setNumeroTarjeta(e.target.value)}
                  placeholder={"Numero de tarjeta"}
                />
              </Col>
            </Row>
            <Row className="mb-2 mb-md-4 mb-lg-7">
              <Col
                sm={12}
                md={4}
                lg={4}
                className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
              >
                <Form.Label>
                  Mes de expiracion: <code>*</code>
                </Form.Label>
              </Col>
              <Col
                sm={12}
                md={8}
                lg={8}
                className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
              >
                <Form.Control
                  type="Number"
                  value={mesExpiracion}
                  onChange={(e) => setMesExpiracion(e.target.value)}
                  placeholder={"Mes de expiracion"}
                />
              </Col>
            </Row>
            <Row className="mb-2 mb-md-4 mb-lg-7">
              <Col
                sm={12}
                md={4}
                lg={4}
                className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
              >
                <Form.Label>
                  Año de expiracion: <code>*</code>
                </Form.Label>
              </Col>
              <Col
                sm={12}
                md={8}
                lg={8}
                className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
              >
                <Form.Control
                  type="Number"
                  value={anoExpiracion}
                  onChange={(e) => setAnoExpiracion(e.target.value)}
                  placeholder={"Año de expiracion"}
                />
              </Col>
            </Row>
            <Row className="mb-2 mb-md-4 mb-lg-7">
              <Col
                sm={12}
                md={4}
                lg={4}
                className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
              >
                <Form.Label>
                  CVV: <code>*</code>
                </Form.Label>
              </Col>
              <Col
                sm={12}
                md={8}
                lg={8}
                className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
              >
                <Form.Control
                  type="Number"
                  value={cvv}
                  onChange={(e) => setCvv(e.target.value)}
                  placeholder={"CVV"}
                />
              </Col>
            </Row>
          </>
        )}
        <Row className="mb-2 mb-md-4 mb-lg-7">
          <Col
            sm={12}
            md={4}
            lg={4}
            className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
          >
            <Form.Label>
              Concepto: <code>*</code>
            </Form.Label>
          </Col>
          <Col
            sm={12}
            md={8}
            lg={8}
            className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
          >
            <Form.Control
              type="text"
              value={concepto}
              onChange={(e) => setConcepto(e.target.value)}
              placeholder={"Ingresa el concepto de pago"}
            />
          </Col>
        </Row>
        <Row className="mb-2 mb-md-4 mb-lg-7">
          <Col
            sm={12}
            md={4}
            lg={4}
            className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
          >
            <Form.Label>
              Cantidad: <code>*</code>
            </Form.Label>
          </Col>
          <Col
            sm={12}
            md={8}
            lg={8}
            className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
          >
            <InputGroup>
              <InputGroup.Text>$</InputGroup.Text>
              <Form.Control
                name="cargo"
                value={cargo}
                onChange={(e) => setCargo(e.target.value)}
                type="number"
                min={1}
              />
            </InputGroup>
          </Col>
        </Row>
        <div className="mb-3 d-flex justify-content-center">
          <Button variant="success" onClick={registrarPagoCliente}>
            <FontAwesomeIcon icon={faCashRegister} />
            &nbsp;Registrar
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default PagosClientes;
