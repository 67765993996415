import React, { useState, useEffect } from "react";
import { Card, Button, Dropdown, Col, Row } from "react-bootstrap";
import { listarServicios } from "../../../api/servicios";
import { getTokenApi, obtenidusuarioLogueado } from "../../../api/auth";
import { obtenerUsuario } from "../../../api/usuarios";
import { listarOrdenesServicioPorCorreoDoctor } from "../../../api/ordenesServicio";
import { withRouter } from "../../../utils/withRouter";
import FechaEntrega from "../../ordenServicio/FechaEntrega";
import { listarDiasFestivos } from "../../../api/diasFestivos";
import { listarPiezasDetalles } from "../../../api/precios";

function Cards({ history, location, busqueda }) {
    const [tipoUsuario, setTipoUsuario] = useState("");
    const [correoUsuario, setCorreoUsuario] = useState("");
    const [listarOrdenes, setListOrdenes] = useState([]);

    const [listarOrdenes2, setListOrdenes2] = useState([]);

    const obtenerOrdenesServicio2 = async () => {
        try {
            const response = await listarDiasFestivos();
            const { data } = response;
            setListOrdenes2(formatModelOrdenes2(data));
        } catch (e) {
            console.error('Error al obtener las órdenes de servicio:', e);
        }
    };

    useEffect(() => {
        obtenerOrdenesServicio2();
    }, []);

    const obtenerDatosUsuario = async () => {
        try {
            const response = await obtenerUsuario(obtenidusuarioLogueado(getTokenApi()));
            const { data } = response;
            setTipoUsuario(data.tipo);
            setCorreoUsuario(data.email);
        } catch (e) {
            console.error("Error al obtener los datos del usuario:", e);
        }
    };

    const [listServicios, setListServicios] = useState([]);

    const obtenerServicios = async () => {
        try {
            const response = await listarServicios();
            const { data } = response;

            // Apply filter based on correoUsuario and busqueda (pieza)
            const filteredData = data.filter((item) =>
                item.emailDoctor === correoUsuario &&
                (
                    item.pieza.toLowerCase().includes(busqueda.toLowerCase()) ||
                    item.clasificacion.toLowerCase().includes(busqueda.toLowerCase()) ||
                    item.material.toLowerCase().includes(busqueda.toLowerCase())
                )
            );


            setListServicios(formatModelServicios(filteredData));
        } catch (error) {
            console.error("Error al listar los servicios:", error);
        }
    };

    useEffect(() => {
        obtenerServicios();
    }, [correoUsuario, busqueda]);

    const [listarPrecio, setListPrecio] = useState([]);

    const obtenerPrecios = async () => {
        try {
            const response = await listarPiezasDetalles();
            const { data } = response;

            // Apply filter based on correoUsuario and busqueda (pieza)
            const filteredData = data.filter((item) =>
            (
                item.nombrePieza.toLowerCase().includes(busqueda.toLowerCase()) ||
                item.nombreDepartamento.toLowerCase().includes(busqueda.toLowerCase()) ||
                item.nombreMaterial.toLowerCase().includes(busqueda.toLowerCase()))
            );


            setListPrecio(formatModelPrecio(filteredData));
        } catch (error) {
            console.error("Error al listar los servicios:", error);
        }
    };

    const obtenerOrdenesServicioo = async () => {
        try {
            const response = await listarOrdenesServicioPorCorreoDoctor(correoUsuario);
            const { data } = response;

            // Extraer los folios de `listServicios`
            const foliosServicios = listServicios.map(servicio => servicio.folio);

            // Filtrar las órdenes donde el campo `folio` está incluido en los folios de `listServicios`
            const datosFiltrados = data.filter(orden =>
                (orden.folio && foliosServicios.includes(orden.folio)) || (orden.folio && orden.folio.toLowerCase().includes(busqueda.toLowerCase()))
            );

            // Formatear los datos filtrados
            const datosOrdenes = formatModelOrdenes(datosFiltrados);

            // Actualizar el estado con los datos formateados
            setListOrdenes(datosOrdenes);
        } catch (e) {
            console.error("Error al obtener las órdenes de servicio:", e);
            // Puedes mostrar un mensaje de error al usuario si lo deseas
        }
    };


    useEffect(() => {
        obtenerPrecios();
    }, [correoUsuario, busqueda]);

    useEffect(() => {
        obtenerOrdenesServicioo();
    }, [correoUsuario, busqueda, listServicios]);

    useEffect(() => {
        obtenerDatosUsuario();
    }, []);

    console.log(tipoUsuario)

    console.log(listServicios)
    console.log(listarOrdenes)

    const [listServicioss, setListServicioss] = useState([]);

    const obtenerServicioss = () => {
        try {
            listarServicios()
                .then((response) => {
                    const { data } = response;
                    console.log(data);
                    if (!listServicioss && data) {
                        setListServicioss(formatModelServicios(data));
                    } else {
                        const datosServicios = formatModelServicios(data);
                        setListServicioss(datosServicios);
                    }
                })
                .catch((e) => { });
        } catch (e) { }
    };

    useEffect(() => {
        obtenerServicioss();
    }, []);


    // Mapping states to their textual representation
    const estadoMapping = {
        "1": "Orden nueva",
        "2": "Recolección asignada",
        "3": "Orden entregada",
        "4": "Fabricando",
        "5": "Orden finalizada",
        "0": "Orden cancelada",
        "6": "En proceso",
        "7": "En ruta de entrega",
        "8": "Entregado",
        "9": "Retrabajo",
    };

    return (
        <div style={{ padding: "1%" }}>
            {/* Mostrar piezas si hay registros */}
            {listServicios.length > 0 && (
                <>
                    <h1>Piezas encontradas</h1>
                    <Row>
                        {listServicios.map((pieza) => (
                            <Col
                                key={pieza.folio}
                                xs={12}
                                md={4}
                                style={{ marginBottom: "1rem" }}
                            >
                                <Card style={{ width: "100%" }}>
                                    <Card.Body>
                                        <Card.Title>Folio: {pieza.folio}</Card.Title>
                                        <Card.Text>
                                            <strong>Pieza:</strong> {pieza.pieza}
                                            <br />
                                            <strong>Precio:</strong> {pieza.precio}
                                            <br />
                                            <strong>Material:</strong> {pieza.material}
                                            <br />
                                            <strong>Departamento:</strong> {pieza.clasificacion}
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </Col>
                        ))}
                    </Row>
                </>
            )}

            {listarPrecio.length > 0 && (
                <>
                    <h1>Detalles de piezas</h1>
                    <Row>
                        {listarPrecio.map((pieza) => (
                            <Col
                                key={pieza.folio}
                                xs={12}
                                md={4}
                                style={{ marginBottom: "1rem" }}
                            >
                                <Card style={{ width: "100%" }}>
                                    <Card.Body>
                                        <Card.Title>Folio: {pieza.folio}</Card.Title>
                                        <Card.Text>
                                            <strong>Pieza:</strong> {pieza.nombrePieza}
                                            <br />
                                            <strong>Costo de producción:</strong>{" "}
                                            {pieza.costoProduccion}
                                            <br />
                                            <strong>Precio público:</strong> {pieza.precioPublico}
                                            <br />
                                            <strong>Material:</strong> {pieza.nombreMaterial}
                                            <br />
                                            <strong>Departamento:</strong> {pieza.departamento}
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </Col>
                        ))}
                    </Row>
                </>
            )}

            {/* Mostrar ordenes si hay registros */}
            {listarOrdenes.length > 0 && (
                <>
                    <h1>Órdenes encontradas</h1>
                    <Row>
                        {listarOrdenes.map((orden) => (
                            <Col
                                key={orden.folio}
                                xs={12}
                                md={4}
                                style={{ marginBottom: "1rem" }}
                            >
                                <Card style={{ width: "100%" }}>
                                    <Card.Body>
                                        <Card.Title>Folio: {orden.folio}</Card.Title>
                                        <Card.Text>
                                            <strong>Trabajos:</strong>
                                            <ul>
                                                {(listServicioss || [])
                                                    .filter((trabajo) => trabajo.folio === orden.folio)
                                                    .map((trabajo, index) => (
                                                        <li key={index}>
                                                            {"Pieza: " +
                                                                trabajo.pieza +
                                                                ", Organo dental: " +
                                                                trabajo.diente +
                                                                ", Tonos: " +
                                                                trabajo.tonoInferior +
                                                                ", " +
                                                                trabajo.tonoMedio +
                                                                ", " +
                                                                trabajo.tonoSuperior +
                                                                ", Descripción: " +
                                                                trabajo.descripcionAdicional}
                                                        </li>
                                                    ))}
                                            </ul>
                                            <strong>Fecha de Entrega:</strong>{" "}
                                            <FechaEntrega
                                                id={orden.id}
                                                fecha={orden.fecha}
                                                ruta={orden.direccionConsultorio}
                                                listarOrdenes={listarOrdenes2}
                                            />
                                            <br />
                                            <strong>Doctor:</strong> {orden.nombreDoctor}
                                            <br />
                                            <strong>Status:</strong>{" "}
                                            {estadoMapping[orden.estado] || "Desconocido"}
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </Col>
                        ))}
                    </Row>
                </>
            )}

            {/* Mostrar mensaje si ambos arrays están vacíos */}
            {listServicios.length == 0 && listarOrdenes.length == 0 && (
                <h1>No se encontraron resultados</h1>
            )}
        </div>
    );
}

function formatModelPrecio(data) {
    const dataTemp = [];
    data.forEach((data) => {
        dataTemp.push({
            id: data._id,
            folio: data.folio,
            nombrePieza: data.nombrePieza.replace(/,/g, ""),
            material: data.material.replace(/,/g, ""),
            departamento: data.departamento,
            costoProduccion: data.costoProduccion,
            precioPublico: data.precioPublico,
            nombreMaterial: data.nombreMaterial.replace(/,/g, ""),
            nombreDepartamento: data.nombreDepartamento.replace(/,/g, ""),
            nombreDepartamento: data.nombreDepartamento.replace(/,/g, ""),
            imagen: data.imagen,
            descripcionPieza: data.descripcion,
            estado: data.estado,
        });
    });
    return dataTemp;
}

function formatModelOrdenes2(data) {
    return data.map((data) => ({
        id: data._id,
        fecha: data.fecha,
        descripcion: data.descripcion,
        estado: data.estado,
    }));
}

function formatModelOrdenes(data) {
    const dataTemp = [];
    data.forEach((data) => {
        dataTemp.push({
            id: data._id,
            folio: data.folio,
            fecha: data.fecha,
            localidad: data.localidad,
            nombreDoctor: data.nombreDoctor,
            nombrePaciente: data.nombrePaciente,
            tipoDentadura: data.tipoDentadura,
            servicios: data.servicios,
            otros: data.otros,
            recibio: data.recibio,
            fechaEntrada: data.fechaEntrada,
            correoDoctor: data.correoDoctor,
            correoConsultorio: data.correoConsultorio,
            estado: data.estado,
            telefonoDoctor: data.telefonoDoctor,
            cancelacion: data.cancelacion,
            cargo: data.cargoOrden,
            cambios: !data.cambios ? "0" : data.cambios,
            aprobado: !data.aprobado ? "No disponible" : data.aprobado,
            direccionConsultorio: data?.direccionConsultorio?.municipio,
        });
    });
    return dataTemp;
}

function formatModelServicios(data) {
    return data.map((item) => {
        const dienteArray = item.diente;
        let diente = "";

        if (Array.isArray(dienteArray)) {
            diente = dienteArray
                .map((d) => d.match(/\d+/)) // Extract numbers from each string
                .filter(Boolean) // Remove any null values resulting from no match
                .join(", "); // Join the numbers with comma and space
        }

        return {
            id: item._id,
            folio: item.folio,
            clasificacion: item.clasificacion,
            material: item.material,
            pieza: item.pieza,
            precio: item.precio,
            descripcionAdicional: item.descripcionAdicional,
            sistemaColor: item.sistemaColor,
            distencion: item.distencion,
            diente: diente,
            tonoInferior: item.tonoInferior,
            tonoMedio: item.tonoMedio,
            tonoSuperior: item.tonoSuperior,
            imagenEsquema: item.imagenEsquema,
            imagenDiente: item.imagenDiente,
            emailDoctor: item.emailDoctor,
        };
    });
}

export default withRouter(Cards);
