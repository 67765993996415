import { useState } from "react";
import { crearCargosOpenpay } from "../../api/openPay";
import { useNavigate } from "react-router-dom";

function OpenpayPaymentPagos() {
  const history = useNavigate(); // o const navigate = useNavigate(); para React Router v6+

  const generateOrderId = () => {
    const timestamp = new Date().getTime();
    const randomString = Math.random().toString(36).substring(2, 15);
    return `${timestamp}-${randomString}`;
  };
  const [transactionUrl, setTransactionUrl] = useState("");
  const [paymentData, setPaymentData] = useState({
    customer: {
      name: 'Juan',
      last_name: 'Vazquez Juarez',
      phone_number: '7731440261',
      email: 'albertoalmarazmeza@gmail.com'
    },
    order_id: generateOrderId(),
    amount: 10.00,
    method: "bank_account",
    due_date: new Date(Date.now() + 24 * 60 * 60 * 1000).toISOString(), // Set due date to 24 hours in the future
    description: "pago",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setPaymentData({ ...paymentData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const nuevoCargo = await crearCargosOpenpay(paymentData);

      if (nuevoCargo.status === 'in_progress') {
        console.log('El pago está en progreso:', nuevoCargo);

        const paymentDetails = nuevoCargo.payment_method;
        if (paymentDetails) {
          // Provide user with details or a link to continue with payment
          window.open(paymentDetails.url_spei);

        } else {
          console.error('No se encontraron detalles del método de pago:', nuevoCargo);
        }
      } else if (nuevoCargo.status === 'completed') {
        console.log('Pago completado:', nuevoCargo);
        // Handle completed payment
      } else {
        console.error('Estado del pago no manejado:', nuevoCargo);
      }
    } catch (error) {
      console.error('Error al realizar el pago:', error.message || error);
      // Show error to user or handle accordingly
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          name="amount"
          placeholder="Monto"
          value={paymentData.amount}
          onChange={handleChange}
        />
        <input
          type="text"
          name="description"
          placeholder="Descripción"
          value={paymentData.description}
          onChange={handleChange}
        />
        <button type="submit">Realizar Cargo</button>
      </form>
    </>
  );
}

export default OpenpayPaymentPagos;
