import { useState, useEffect } from "react";
import {
  getTokenApi,
  obtenidusuarioLogueado,
  logoutApi,
} from "../../../api/auth";
import { obtenerUsuario } from "../../../api/usuarios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import perfil from "../../../assets/img/perfil.jpg";
import { Dropdown, NavDropdown, Badge, Button, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBell,
  faBroom,
  faBellConcierge,
  faCalendarDay,
  faClock,
  faEye,
  faFileCircleCheck,
  faFilePen,
  faFileShield,
  faMessage,
  faNewspaper,
  faPlaneArrival,
  faQuestion,
  faTriangleExclamation,
  faTruck,
  faWarning,
  faCircleUser,
} from "@fortawesome/free-solid-svg-icons";
import { actualizarEvento, listarEvento } from "../../../api/eventos";
import "./styleNotifications.css";
import { listarVacacionesProximas } from "../../../api/vacaciones";
import { NotificationContainer } from "react-notifications";
import "react-notifications/lib/notifications.css";
import { LogsInformativos } from "../../logs/logs";
import { listarProductosAgotandose } from "../../../api/almacenMateriasPrimas";
import BasicModal from "../../Modal/BasicModal/BasicModal";
import Permisos from "./permisos/Permisos";
import AvisoPrivacidad from "../cliente/AvisoProvacidad";
import { listarConfiguracionRutas } from "../../../api/configuracionRutas";
import "dayjs/locale/es";
import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";

export function HeaderDash({ busqueda, setBusqueda }) {
  dayjs.locale("es"); // use Spanish locale globally
  dayjs.extend(localizedFormat);

  const [listarRutas, setListRutas] = useState([]);
  const [tomorrowDayName, setTomorrowDayName] = useState("");
  const EMAIL_SENT_FLAG_KEY = "emailSentFlag";
  const LAST_RUN_DATE_KEY = "lastRunDate";

  // Helper function to get the day name in Spanish
  const getDayName = (date) => {
    const days = [
      "domingo",
      "lunes",
      "martes",
      "miercoles",
      "jueves",
      "viernes",
      "sabado",
    ];
    return days[date.getDay()];
  };

  // Helper function to remove accents from a string
  const removeAccents = (str) => {
    return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  };

  // Helper function to get today's date in 'YYYY-MM-DD' format
  const getFormattedDate = (date) => {
    return date.toISOString().split("T")[0];
  };

  // Helper function to get the email sent flag
  const getEmailSentFlag = () => {
    return localStorage.getItem(EMAIL_SENT_FLAG_KEY) === "true";
  };

  // Helper function to set the email sent flag
  const setEmailSentFlag = (flag) => {
    localStorage.setItem(EMAIL_SENT_FLAG_KEY, flag.toString());
  };

  // Helper function to check if the flag needs to be reset for the new day
  const checkAndResetFlag = () => {
    const lastRunDate = localStorage.getItem(LAST_RUN_DATE_KEY);
    const today = getFormattedDate(new Date());
    if (lastRunDate !== today) {
      localStorage.setItem(LAST_RUN_DATE_KEY, today);
      setEmailSentFlag(true);
    }
  };

  // First useEffect: Calculate and set the name of tomorrow's day
  useEffect(() => {
    const getTomorrowDayName = () => {
      const today = new Date();
      const tomorrow = new Date(today);
      tomorrow.setDate(today.getDate() + 1);
      return removeAccents(getDayName(tomorrow));
    };

    const tomorrow = getTomorrowDayName();
    setTomorrowDayName(tomorrow);
  }, []);

  console.log(tomorrowDayName);

  // Second useEffect: Fetch routes and filter by tomorrow's day name
  useEffect(() => {
    if (tomorrowDayName) {
      listarConfiguracionRutas()
        .then((response) => {
          const { data } = response;
          const datosRutas = formatModelRutas(data);
          console.log(datosRutas);

          // Filter the routes by tomorrow's day name
          const rutasFiltradas = datosRutas.filter(
            (ruta) => ruta.dia === tomorrowDayName
          );
          setListRutas(rutasFiltradas);
        })
        .catch((e) => {
          console.error(e);
        });
    }
  }, [tomorrowDayName]);

  const redirecciona = useNavigate();

  const [idUsuario, setIdeUsuario] = useState("");
  const [nombreUsuario, setNombreUsuario] = useState("");
  const [departamentoUsuario, setDepartamentoUsuario] = useState("");
  const [tipoUsuario, setTipoUsuario] = useState("");
  const [datosCargados, setDatosCargados] = useState(false);

  const obtenerDatosUsuario = async () => {
    try {
      const response = await obtenerUsuario(
        obtenidusuarioLogueado(getTokenApi())
      );
      const { data } = response;
      setIdeUsuario(data._id);
      setNombreUsuario(data.nombre);
      setDepartamentoUsuario(data.departamento);
      setTipoUsuario(data.tipo);
      setDatosCargados(true);
    } catch (e) {
      if (e.message === "Network Error") {
        toast.error("Conexión al servidor no disponible");
      } else {
        console.error(e);
      }
      setDatosCargados(true); // Incluso si falla, consideramos que los datos se han "cargado" para evitar un ciclo infinito
    }
  };

  useEffect(() => {
    obtenerDatosUsuario();
  }, []);

  useEffect(() => {
    if (datosCargados && !idUsuario) {
      redirecciona("/login");
    }
  }, [datosCargados, idUsuario, redirecciona]);

  //Para cerrar la sesion
  const cerrarSesion = () => {
    LogsInformativos("Se ha cerraado la sesion del usuario " + nombreUsuario);
    logoutApi();
    //window.location.reload();
    redirecciona("/login");
    toast.success("Sesión cerrada");
  };
  const [listEventos, setListEventos] = useState([]);
  const [numNotifications, setNumNotifications] = useState(0);

  const obtieneEventos = () => {
    if (tipoUsuario === "administrador") {
      listarEvento()
        .then((response) => {
          if (response && response.data) {
            const { data } = response;
            const eventosNoLeidos = formatModelEventos(data).filter(
              (evento) => evento.leido === "0"
            );
            setNumNotifications(eventosNoLeidos.length);
            setListEventos(eventosNoLeidos);
          } else {
            console.error(
              "Error: No se recibieron datos válidos de listarEvento"
            );
          }
        })
        .catch((error) => {
          console.error("Error al obtener datos de listarEvento:", error);
        });
    } else if (tipoUsuario === "rHumanos") {
      listarVacacionesProximas()
        .then((response) => {
          if (response && response.data) {
            const { data } = response;
            const datosVacaciones = formatModelVacaciones(data);
            setNumNotifications(datosVacaciones.length);
            setListEventos(datosVacaciones);
          } else {
            console.error(
              "Error: No se recibieron datos válidos de listarVacacionesProximas"
            );
          }
        })
        .catch((error) => {
          console.error(
            "Error al obtener datos de listarVacacionesProximas:",
            error
          );
        });
    } else {
      console.error("Error: Tipo de usuario no reconocido");
    }
  };

  useEffect(() => {
    obtieneEventos();
    const intervalId = setInterval(obtieneEventos, 300000);
    return () => clearInterval(intervalId);
  }, [tipoUsuario]);

  const actualizarLeido = (id) => {
    console.log(id);
    try {
      const dataTemp = {
        leido: 1,
      };
      console.log(dataTemp);
      actualizarEvento(id, dataTemp).then((response) => {
        const { data } = response;
        obtieneEventos();
      });
    } catch (e) {
      console.log(e);
    }
  };

  const [listarProductos, setListProductos] = useState([]);

  const obtenerProductosAgotandose = () => {
    try {
      listarProductosAgotandose()
        .then((response) => {
          const { data } = response;

          if (!listarProductos && data) {
            setListProductos(formatModelProductos(data));
          } else {
            const datosProductos = formatModelProductos(data);
            setListProductos(datosProductos);
          }
        })
        .catch((e) => {});
    } catch (e) {}
  };

  useEffect(() => {
    obtenerProductosAgotandose();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      if (listarProductos.length > 0 && tipoUsuario == "almacen") {
        toast.warning(
          "Hay productos por agotarse, por favor revisa la tabla de productos próximos a agotarse",
          { hideProgressBar: true }
        );
      }
    }, 900000);

    return () => clearInterval(interval); // Limpiar el intervalo cuando el componente se desmonte o cuando listarProductos cambie
  }, [listarProductos]);

  const [showModal, setShowModal] = useState(false);
  const [contentModal, setContentModal] = useState(null);
  const [titulosModal, setTitulosModal] = useState(null);

  const vistaPermisos = (content) => {
    setTitulosModal("Permisos otorgados");
    setContentModal(content);
    setShowModal(true);
  };

  const avisoPrivacidad = (content) => {
    setTitulosModal("Aviso de privacidad");
    setContentModal(content);
    setShowModal(true);
  };

  return (
    <>
      <NotificationContainer />
      {idUsuario != "" && (
        <>
          <nav className="main-header navbar navbar-expand">
            {/* Left navbar links */}
            <ul className="navbar-nav menuH">
              <li className="nav-item">
                <a
                  className="nav-link"
                  data-widget="pushmenu"
                  href="#"
                  role="button"
                >
                  <i className="fas fa-bars" />
                </a>
              </li>
              <li className="nav-item d-none d-sm-inline-block"></li>
            </ul>
            {/* Right navbar links */}
            <ul class="navbar-nav ml-auto perfilDropdown text-right">
              {(tipoUsuario === "contabilidad" ||
                tipoUsuario === "administrador") && (
                <Button
                  variant="link"
                  style={{ textDecoration: "none", color: "#fff" }}
                  onClick={() =>
                    vistaPermisos(<Permisos setShow={setShowModal} />)
                  }
                  title="Permisos de módulos"
                >
                  <FontAwesomeIcon icon={faFileShield} />
                </Button>
              )}
              {tipoUsuario === "doctor" && (
                <>
                  <div
                    className="buttonSearchBar"
                  >
                    <Form.Control
                      value={busqueda}
                      onChange={(e) => setBusqueda(e.target.value)}
                      type="text"
                      placeholder="Escribir pieza dental o folio de ODS"
                      style={{width:"350px"}}
                    />
                    <Button
                      variant="outline-secondary"
                      onClick={() => setBusqueda("")}
                    >
                      <FontAwesomeIcon icon={faBroom} />
                    </Button>
                  </div>
                  <Button
                    variant="link"
                    style={{ textDecoration: "none", color: "#fff" }}
                    onClick={() =>
                      avisoPrivacidad(
                        <AvisoPrivacidad setShow={setShowModal} />
                      )
                    }
                    title="Permisos de módulos"
                  >
                    <FontAwesomeIcon icon={faFilePen} />
                  </Button>
                </>
              )}
              {tipoUsuario === "administrador" && (
                <li>
                  <Dropdown>
                    <Dropdown.Toggle
                      variant="link"
                      id="dropdown-basic"
                      style={{ textDecoration: "none", color: "#FFF" }}
                    >
                      <FontAwesomeIcon icon={faBell} />
                      &nbsp;
                      {numNotifications != 0 && (
                        <Badge pill bg="danger">
                          {numNotifications}
                        </Badge>
                      )}
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      {numNotifications != 0 ? (
                        listEventos.map((evento) => (
                          <Dropdown.Item
                            key={evento.id}
                            className={
                              evento.leido == 0 ? "dropNot" : "notGral"
                            }
                            onClick={() => actualizarLeido(evento.id)}
                          >
                            <div className="containerNot">
                              <div className="iconNot">
                                <div className="containerIcon">
                                  {evento.tipoEvento === "Otro" ? (
                                    <FontAwesomeIcon icon={faQuestion} />
                                  ) : evento.tipoEvento === "Mensaje" ? (
                                    <FontAwesomeIcon icon={faMessage} />
                                  ) : evento.tipoEvento === "Advertencia" ? (
                                    <FontAwesomeIcon
                                      icon={faTriangleExclamation}
                                    />
                                  ) : evento.tipoEvento === "Observación" ? (
                                    <FontAwesomeIcon icon={faEye} />
                                  ) : evento.tipoEvento === "Registro" ? (
                                    <FontAwesomeIcon icon={faBellConcierge} />
                                  ) : evento.tipoEvento === "Entrega" ? (
                                    <FontAwesomeIcon icon={faTruck} />
                                  ) : evento.tipoEvento === "Entregada" ? (
                                    <FontAwesomeIcon icon={faFileCircleCheck} />
                                  ) : (
                                    <FontAwesomeIcon icon={faQuestion} />
                                  )}
                                </div>
                              </div>
                              <div className="contentNot">
                                <label className="labelUserNot">
                                  {evento.usuario.split(" ").length >= 3
                                    ? evento.usuario
                                        .split(" ")
                                        .slice(0, 2)
                                        .join(" ")
                                    : evento.usuario}
                                </label>
                                &nbsp;
                                {evento.tipoEvento === "Registro" ? (
                                  "Realizó el registro de la orden"
                                ) : evento.tipoEvento === "Mensaje" ? (
                                  <>
                                    <label className="lblMsgNot">
                                      Envió el mensaje:
                                    </label>
                                    <p className="textMotNot">
                                      <label>{evento.motivoEvento}</label>
                                      &nbsp;
                                      <label className="lblEn">en:</label>
                                    </p>
                                  </>
                                ) : evento.tipoEvento === "Advertencia" ? (
                                  <>
                                    <label className="lblMsgNot">
                                      Agregó la advertencia:
                                    </label>
                                    <p className="textMotNot">
                                      <label>{evento.motivoEvento}</label>
                                      &nbsp;
                                      <label className="lblEn">en:</label>
                                    </p>
                                  </>
                                ) : evento.tipoEvento === "Observación" ? (
                                  <>
                                    <label className="lblMsgNot">
                                      Agregó una observación:
                                    </label>
                                    <p className="textMotNot">
                                      <label>{evento.motivoEvento}</label>
                                      &nbsp;
                                      <label className="lblEn">en:</label>
                                    </p>
                                  </>
                                ) : evento.tipoEvento === "Otro" ? (
                                  <>
                                    <label className="lblMsgNot">Agregó:</label>
                                    <p className="textMotNot">
                                      <label>{evento.motivoEvento}</label>
                                      &nbsp;
                                      <label className="lblEn">en:</label>
                                    </p>
                                  </>
                                ) : evento.tipoEvento === "Entrega" ? (
                                  <>
                                    <label className="lblMsgNot">Orden:</label>
                                    &nbsp;
                                    <label className="textPNot">
                                      {evento.folioOrden}
                                    </label>
                                    <p className="textMotNot">
                                      <label className="lblEn">
                                        En ruta para entrega
                                      </label>
                                    </p>
                                  </>
                                ) : evento.tipoEvento === "Entregada" ? (
                                  <>
                                    <label className="lblMsgNot">Orden:</label>
                                    &nbsp;
                                    <label className="textPNot">
                                      {evento.folioOrden}
                                    </label>
                                    <p className="textMotNot">
                                      <label className="lblEn">
                                        Entregada correctamente
                                      </label>
                                    </p>
                                  </>
                                ) : (
                                  evento.motivoEvento
                                )}
                                &nbsp;
                                {
                                  (evento.tipoEvento = !"Entrega" && (
                                    <p className="textPNot">
                                      {evento.folioOrden}
                                    </p>
                                  ))
                                }
                              </div>
                              <div className="dateNot">
                                <FontAwesomeIcon icon={faClock} />
                                <p>{dayjs(evento.fecha).format("LL")}</p>
                              </div>
                            </div>
                          </Dropdown.Item>
                        ))
                      ) : (
                        <Dropdown.Item>No hay notificaciones</Dropdown.Item>
                      )}
                    </Dropdown.Menu>
                  </Dropdown>
                </li>
              )}
              {tipoUsuario === "rHumanos" && (
                <li>
                  <Dropdown>
                    <Dropdown.Toggle
                      variant="link"
                      id="dropdown-basic"
                      style={{ textDecoration: "none", color: "#FFF" }}
                    >
                      <FontAwesomeIcon icon={faBell} />
                      &nbsp;
                      {numNotifications != 0 && (
                        <Badge pill bg="danger">
                          {numNotifications}
                        </Badge>
                      )}
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      {numNotifications != 0 ? (
                        listEventos.map((evento) => (
                          <Dropdown.Item key={evento.id} className="notGral">
                            <div className="containerNot">
                              <div className="iconNot">
                                <div className="containerIcon">
                                  <FontAwesomeIcon icon={faCalendarDay} />
                                </div>
                              </div>
                              <div style={{ marginLeft: "2%" }}>
                                <label style={{ fontWeight: "normal" }}>
                                  Vacaciones Próximas el día{" "}
                                  {dayjs(evento.fechaInicio).format("LL")} de:
                                </label>
                                &nbsp;
                                <p>
                                  <label>{evento.nombre}</label>
                                </p>
                              </div>
                            </div>
                          </Dropdown.Item>
                        ))
                      ) : (
                        <Dropdown.Item>No hay notificaciones</Dropdown.Item>
                      )}
                    </Dropdown.Menu>
                  </Dropdown>
                </li>
              )}

              <li className="perfilCierreBarra">
                <Dropdown>
                  <Dropdown.Toggle
                    variant="link"
                    className="dropPerfil"
                    id="dropdown-basic"
                  >
                    <FontAwesomeIcon icon={faCircleUser} className="iconoUsuario" />
                    <span className="nombreUsuario">
                      {tipoUsuario != "departamento"
                        ? nombreUsuario
                        : departamentoUsuario}
                    </span>
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <NavDropdown.Item onClick={() => cerrarSesion()}>
                      <i class="fas fa-user-times iconoCerrarS" /> Cerrar sesión
                    </NavDropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </li>
            </ul>
          </nav>
        </>
      )}
      <BasicModal show={showModal} setShow={setShowModal} title={titulosModal}>
        {contentModal}
      </BasicModal>
    </>
  );
}

function formatModelEventos(data) {
  const dataTemp = [];
  data.forEach((data) => {
    dataTemp.push({
      id: data._id,
      nombre: data.nombre,
      fecha: data.fecha,
      folioOrden: data.folioOrden,
      usuario: data.usuario,
      tipoEvento: data.tipoEvento,
      motivoEvento: data.motivoEvento,
      leido: data.leido,
    });
  });
  return dataTemp;
}

function formatModelVacaciones(data) {
  const dataTemp = [];
  data.forEach((data) => {
    dataTemp.push({
      id: data._id,
      nombre: data.empleado,
      fechaInicio: data.fecha.inicio,
      folioOrden: data.folioOrden,
    });
  });
  return dataTemp;
}

function formatModelProductos(data) {
  const dataTemp = [];
  data.forEach((data) => {
    dataTemp.push({
      id: data._id,
      clave: data.clave,
      claveAlterna: data.claveAlterna,
      producto: data.producto,
      minimo: data.minimo,
      maximo: data.maximo,
      existencia: data.existencia,
      unidadMedida: data.unidadMedida,
      entrada: data.entrada,
      salida: data.salida,
      devolucion: data.devolucion,
      existenciaReal:
        Number(data.existencia) +
        Number(data.entrada) +
        Number(data.devolucion) -
        Number(data.salida),
      ubicacion: data.ubicacion,
      departamento: data.departamento,
      categoria: data.categoria,
      estado: data.estado,
      imagen: data.imagen,
    });
  });
  return dataTemp;
}

function formatModelRutas(data) {
  const dataTemp = [];
  const det_dienteArray = data.nombre;
  let det_diente = "";

  if (Array.isArray(det_dienteArray)) {
    det_diente = det_dienteArray
      .map((d) => d.match(/\d+/)) // Extract numbers from each string
      .filter(Boolean) // Remove any null values resulting from no match
      .join(", "); // Join the numbers with commas
  }
  data.forEach((data) => {
    dataTemp.push({
      id: data._id,
      folio: data.folio,
      nombre: data.nombre.join(", "),
      descripcion: data.descripcion,
      dia: data.dia,
      datosMensajero: data.datosMensajero,
      datosVehiculo: data.datosVehiculo,
      estado: data.estado,
    });
  });
  return dataTemp;
}
