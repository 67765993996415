import axios from 'axios';
import { API_OPENPAY } from '../utils/constants';

const YOUR_API_KEY = 'sk_bf32c8b3b2d342baace620c67b7f10c2'; // Reemplaza con tu propia API Key
const YOUR_API_SECRET = 'pk_2b13389c063a4b789e8e81e4040557e1'; // Reemplaza con tu propia Secret Key

export async function crearUsuarioOpenpay(customerData) {

    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': `Basic ${btoa(`${YOUR_API_KEY}:${YOUR_API_SECRET}`)}`,
        }
    };

    try {
        const response = await axios.post(API_OPENPAY + '/customers/', customerData, config);

        if (response.status === 200 || response.status === 201) {
            const newCustomer = response.data;
            console.log('Usuario creado:', newCustomer);
            return newCustomer;
        } else {
            console.error('Error al crear usuario:', response.statusText);
            throw new Error('Error al crear usuario');
        }
    } catch (error) {
        console.error('Error de red:', error);
        throw error;
    }
}

export async function crearTarjetaOpenpay(tarjetData) {

    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': `Basic ${btoa(`${YOUR_API_KEY}:${YOUR_API_SECRET}`)}`,
        }
    };

    try {
        const response = await axios.post(API_OPENPAY + '/cards/', tarjetData, config);

        if (response.status === 200 || response.status === 201) {
            const newCustomer = response.data;
            console.log('Tarjeta creada:', newCustomer);
            return newCustomer;
        } else {
            console.error('Error al crear la tarjeta:', response.statusText);
            throw new Error('Error al crear la tarjeta');
        }
    } catch (error) {
        console.error('Error de red:', error);
        throw error;
    }
}

export async function crearCargosOpenpay(chargesData) {

    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': `Basic ${btoa(`${YOUR_API_KEY}:${YOUR_API_SECRET}`)}`,
        }
    };

    try {
        const response = await axios.post(API_OPENPAY + '/charges/', chargesData, config);

        if (response.status === 200 || response.status === 201) {
            const newCustomer = response.data;
            console.log('Cargo creado:', newCustomer);
            return newCustomer;
        } else {
            console.error('Error al crear el cargo:', response.statusText);
            throw new Error('Error al crear el cargo');
        }
    } catch (error) {
        console.error('Error de red:', error);
        throw error;
    }
}
