import React, { useState, useEffect } from "react";
import { Card, Button, Dropdown } from "react-bootstrap";
import {
  faPen,
  faTrash,
  faShippingFast,
  faHandHolding,
  faMoneyCheckDollar,
  faCancel,
  faPlus,
  faFileWord,
  faBars,
  faFilePdf
} from "@fortawesome/free-solid-svg-icons";
import FechaEntrega from "./FechaEntrega";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  listarOrdenesServicio,
  listarOrdenesServicioPorCorreoConsultorio,
  listarOrdenesServicioPorCorreoDoctor,
  listarOrdenesServicioPorCorreoMensajero,
} from "../../api/ordenesServicio";
import { listarServicios } from "../../api/servicios";
import { getTokenApi, obtenidusuarioLogueado } from "../../api/auth";
import { obtenerUsuario } from "../../api/usuarios";
import EliminarOrdenServicio from "./EliminarOrdenServicio";
import ModificarOrdenServicio from "./ModificarOrdenServicio";
import EntregarOrdenServicio from "./EntregarOrdenServicio";
import CancelarOrdenServicio from "./CancelarOrdenServicio";
import MaterialEntrega from "./material/MaterialEntrega";
import PagosOrdenesM from "./pagosOrdenesM/PagosOrdenesM";
import ProcesoCancelacionOrdenServicio from "./ProcesoCancelacionOrdenServicio";
import AsignarRetrabajo from "./AsignarRetrabajo";
import ActualizarEstadoCambiosOrdenServicio from "./ActualizarEstadoCambiosOrdenServicio";
import BasicModal from "../Modal/BasicModal/BasicModal";
import { map } from "lodash";
import { listarDiasFestivos } from "../../api/diasFestivos";
import { withRouter } from "../../utils/withRouter";
import ViewPDFTable from "./OrdenPDFTabla";

function CardODS({ history, location }) {
  const [tipoUsuario, setTipoUsuario] = useState("");
  const [correoUsuario, setCorreoUsuario] = useState("");
  const [listarOrdenes, setListOrdenes] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [contentModal, setContentModal] = useState(null);
  const [titulosModal, setTitulosModal] = useState(null);

  const obtenerDatosUsuario = async () => {
    try {
      const response = await obtenerUsuario(obtenidusuarioLogueado(getTokenApi()));
      const { data } = response;
      setTipoUsuario(data.tipo);
      setCorreoUsuario(data.email);
    } catch (e) {
      console.error("Error al obtener los datos del usuario:", e);
    }
  };

  useEffect(() => {
    obtenerDatosUsuario();
  }, []);

  console.log(tipoUsuario)

  const obtenerOrdenesServicio = async () => {
    try {
      let response;
      if (tipoUsuario == "administrador" || tipoUsuario == "mesa de control") {
        response = await listarOrdenesServicio();
      } else if (tipoUsuario == "consultorio") {
        response = await listarOrdenesServicioPorCorreoConsultorio(correoUsuario);
      } else if (tipoUsuario == "doctor") {
        response = await listarOrdenesServicioPorCorreoDoctor(correoUsuario);
      } else if (tipoUsuario == "mensajero") {
        response = await listarOrdenesServicioPorCorreoMensajero(correoUsuario);
      }
      const { data } = response;
      setListOrdenes(formatModelOrdenes(data)); // Aquí se puede agregar la función formatModelOrdenes si es necesario
    } catch (e) {
      console.error("Error al obtener las órdenes de servicio:", e);
    }
  };

  useEffect(() => {
    obtenerOrdenesServicio();
  }, [correoUsuario, tipoUsuario, location]);

  console.log(listarOrdenes)

  const handleShowModal = (title, content) => {
    setTitulosModal(title);
    setContentModal(content);
    setShowModal(true);
  };

  const pdfOrden = (content) => {
    setTitulosModal("PDF de la orden de servicio");
    setContentModal(content);
    setShowModal(true);
  };

  const procesoCancelacionOrden = (content) => {
    setTitulosModal("Proceso cancelacion orden de cliente");
    setContentModal(content);
    setShowModal(true);
  };

  const modificarOrden = (content) => {
    setTitulosModal("Modificar orden de cliente");
    setContentModal(content);
    setShowModal(true);
  };

  const verOrden = (content) => {
    setTitulosModal("Detalles de la orden de cliente");
    setContentModal(content);
    setShowModal(true);
  };

  const eliminarOrden = (content) => {
    setTitulosModal("Eliminar orden de cliente");
    setContentModal(content);
    setShowModal(true);
  };

  const cancelarOrden = (content) => {
    setTitulosModal("Cancelar orden de cliente");
    setContentModal(content);
    setShowModal(true);
  };

  const transferirOrden = (content) => {
    setTitulosModal("Transferir orden de cliente");
    setContentModal(content);
    setShowModal(true);
  };

  const entregarOrden = (content) => {
    setTitulosModal("Entregar orden de cliente");
    setContentModal(content);
    setShowModal(true);
  };

  const recibirPago = (content) => {
    setTitulosModal("Pago de orden de cliente");
    setContentModal(content);
    setShowModal(true);
  };

  const recibirMaterial = (content) => {
    setTitulosModal("Recibir material de la orden");
    setContentModal(content);
    setShowModal(true);
  };

  const progresoOrden = (content) => {
    setTitulosModal("Progreso");
    setContentModal(content);
    setShowModal(true);
  };

  const asignarRetrabajo = (content) => {
    setTitulosModal("Asignar retrabajo");
    setContentModal(content);
    setShowModal(true);
  };

  const actualizarEstadoCambios = (content) => {
    setTitulosModal("Confirma los cambios");
    setContentModal(content);
    setShowModal(true);
  };

  const renderAcciones = (rowData) => {
    function convertirObjeto(obj) {
      return [
        obj.id,
        obj.folio,
        obj.fecha,
        obj.localidad,
        obj.nombreDoctor,
        obj.nombrePaciente,
        obj.tipoDentadura,
        obj.servicios,
        obj.recibio,
        obj.fechaEntrada,
        obj.correoDoctor,
        obj.correoConsultorio,
        obj.Imagenes,
        obj.estado,
        obj.progreso,
        obj.telefonoDoctor,
        obj.cancelacion,
        obj.cambios,
        obj.aprobado,
        obj.direccionConsultorio,
        obj.dia,
        obj.Acciones
      ];
    }

    rowData = convertirObjeto(rowData);

    return tipoUsuario !== "consultorio" &&
      tipoUsuario !== "cProduccion" &&
      tipoUsuario !== "doctor" &&
      tipoUsuario !== "mensajero" &&
      rowData[13] !== "0" ? (
      <>
        <Dropdown>
          <Dropdown.Toggle
            style={{ backgroundColor: "#0c1b30", borderColor: "#0c1b30" }}
            id="dropdown-basic"
          >
            <FontAwesomeIcon icon={faBars} />
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {(tipoUsuario === "mesa de control" ||
              tipoUsuario === "administrador") && (
                <>
                  {tipoUsuario != "administrador" &&
                    rowData[17] == "2" && (
                      <Dropdown.Item
                        onClick={() =>
                          actualizarEstadoCambios(
                            <ActualizarEstadoCambiosOrdenServicio
                              history={history}
                              aprobado={"mesa de control"}
                              setShow={setShowModal}
                              data={rowData}
                            />
                          )
                        }
                      >
                        <FontAwesomeIcon
                          icon={faPlus}
                          style={{ color: "#007bff" }}
                        />
                        &nbsp;Confirmar cambios
                      </Dropdown.Item>
                    )}
                  <Dropdown.Item
                    onClick={() =>
                      modificarOrden(
                        <ModificarOrdenServicio
                          history={history}
                          setShow={setShowModal}
                          data={rowData}
                        />
                      )
                    }
                  >
                    <FontAwesomeIcon
                      icon={faPen}
                      style={{ color: "#fd7e14" }}
                    />
                    &nbsp;Editar Orden
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() =>
                      pdfOrden(
                        <ViewPDFTable
                          history={history}
                          setShow={setShowModal}
                          ordenServicio={rowData[1]}
                        />
                      )
                    }
                  >
                    <FontAwesomeIcon
                      icon={faFilePdf}
                      style={{ color: "#fd7e14" }}
                    />
                    &nbsp;Descargar PDF
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() =>
                      eliminarOrden(
                        <EliminarOrdenServicio
                          history={history}
                          setShow={setShowModal}
                          data={rowData}
                        />
                      )
                    }
                  >
                    <FontAwesomeIcon
                      icon={faTrash}
                      style={{ color: "#dc3545" }}
                    />
                    &nbsp; Cancelar Orden
                  </Dropdown.Item>
                </>
              )}
          </Dropdown.Menu>
        </Dropdown>
      </>
    ) : tipoUsuario === "mensajero" && rowData[13] === "4" ? (
      <>
        <Dropdown>
          <Dropdown.Toggle
            style={{ backgroundColor: "#0c1b30", borderColor: "#0c1b30" }}
            id="dropdown-basic"
          >
            <FontAwesomeIcon icon={faShippingFast} />
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item
              onClick={() =>
                entregarOrden(
                  <EntregarOrdenServicio
                    history={history}
                    setShow={setShowModal}
                    data={rowData}
                  />
                )
              }
            >
              <FontAwesomeIcon
                icon={faPen}
                style={{ color: "#fd7e14" }}
              />
              &nbsp;Entregar orden
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() =>
                recibirPago(
                  <PagosOrdenesM
                    history={history}
                    setShow={setShowModal}
                    data={rowData}
                  />
                )
              }
            >
              <FontAwesomeIcon
                icon={faMoneyCheckDollar}
                style={{ color: "#28a745" }}
              />
              &nbsp;Recibir pago
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </>
    ) : tipoUsuario === "mensajero" && rowData[13] === "2" ? (
      <>
        <Dropdown>
          <Dropdown.Toggle
            style={{ backgroundColor: "#0c1b30", borderColor: "#0c1b30" }}
            id="dropdown-basic"
          >
            <FontAwesomeIcon icon={faShippingFast} />
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item
              onClick={() =>
                recibirMaterial(
                  <MaterialEntrega
                    history={history}
                    setShow={setShowModal}
                    data={rowData}
                  />
                )
              }
            >
              <FontAwesomeIcon
                icon={faHandHolding}
                style={{ color: "#fd7e14" }}
              />
              &nbsp;Recibir material
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </>
    ) : rowData[16] === "1" && tipoUsuario === "consultorio" ? (
      <Dropdown>
        <Dropdown.Toggle
          style={{ backgroundColor: "#0c1b30", borderColor: "#0c1b30" }}
          id="dropdown-basic"
        >
          <FontAwesomeIcon icon={faBars} />
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item
            onClick={() =>
              procesoCancelacionOrden(
                <ProcesoCancelacionOrdenServicio
                  history={history}
                  setShow={setShowModal}
                  data={rowData}
                />
              )
            }
          >
            <FontAwesomeIcon
              icon={faCancel}
              style={{ color: "#dc3545" }}
            />
            &nbsp;Cancelar orden
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    ) : tipoUsuario === "doctor" ? (
      <Dropdown>
        <Dropdown.Toggle
          style={{ backgroundColor: "#0c1b30", borderColor: "#0c1b30" }}
          id="dropdown-basic"
        >
          <FontAwesomeIcon icon={faBars} />
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item
            onClick={() =>
              pdfOrden(
                <ViewPDFTable
                  history={history}
                  setShow={setShowModal}
                  ordenServicio={rowData[1]}
                />
              )
            }
          >
            <FontAwesomeIcon
              icon={faFilePdf}
              style={{ color: "#fd7e14" }}
            />
            &nbsp;Descargar PDF
          </Dropdown.Item>
          {rowData[17] == "2" && (
            <Dropdown.Item
              onClick={() =>
                actualizarEstadoCambios(
                  <ActualizarEstadoCambiosOrdenServicio
                    history={history}
                    aprobado={"el cliente"}
                    setShow={setShowModal}
                    data={rowData}
                  />
                )
              }
            >
              <FontAwesomeIcon
                icon={faPlus}
                style={{ color: "#007bff" }}
              />
              &nbsp;Confirmar cambios
            </Dropdown.Item>
          )}
          {rowData[13] == "8" && (
            <Dropdown.Item
              onClick={() =>
                asignarRetrabajo(
                  <AsignarRetrabajo
                    history={history}
                    setShow={setShowModal}
                    data={rowData}
                  />
                )
              }
            >
              <FontAwesomeIcon
                icon={faFileWord}
                style={{ color: "#FFA500" }}
              />
              &nbsp;Asignar Retrabajo
            </Dropdown.Item>
          )}
        </Dropdown.Menu>
      </Dropdown>
    ) : (
      "No disponibles"
    );
  };

  const [listarOrdenes2, setListOrdenes2] = useState([]);

  const obtenerOrdenesServicio2 = async () => {
    try {
      const response = await listarDiasFestivos();
      const { data } = response;
      setListOrdenes2(formatModelOrdenes2(data));
    } catch (e) {
      console.error('Error al obtener las órdenes de servicio:', e);
    }
  };

  useEffect(() => {
    obtenerOrdenesServicio2();
  }, []);

  const [listServicios, setListServicios] = useState([]);

  const obtenerServicios = () => {
    try {
      listarServicios()
        .then((response) => {
          const { data } = response;
          console.log(data);
          if (!listServicios && data) {
            setListServicios(formatModelServicios(data));
          } else {
            const datosServicios = formatModelServicios(data);
            setListServicios(datosServicios);
          }
        })
        .catch((e) => { });
    } catch (e) { }
  };

  useEffect(() => {
    obtenerServicios();
  }, []);

  console.log(listServicios)

  // Mapping states to their textual representation
  const estadoMapping = {
    "1": "Orden nueva",
    "2": "Recolección asignada",
    "3": "Orden entregada",
    "4": "Fabricando",
    "5": "Orden finalizada",
    "0": "Orden cancelada",
    "6": "En proceso",
    "7": "En ruta de entrega",
    "8": "Entregado",
    "9": "Retrabajo",
  };

  return (
    <div>
      {listarOrdenes.map((orden) => (
        <Card key={orden.folio} style={{ width: '100%', marginBottom: '1rem' }}>
          <Card.Body>
            <Card.Title>Folio: {orden.folio}</Card.Title>
            <Card.Text>
              <strong>Trabajos:</strong>
              <ul>
                {(listServicios || [])
                  .filter(trabajo => trabajo.folio === orden.folio)
                  .map((trabajo, index) => (
                    <li key={index}>{"Pieza: " + trabajo.pieza + ", Organo dental: " + trabajo.diente + ", Tonos: " + trabajo.tonoInferior + ", " + trabajo.tonoMedio + ", " + trabajo.tonoSuperior + ", Descripcion: " + trabajo.descripcionAdicional}</li>
                  ))
                }
              </ul>
              <strong>Fecha de Entrega:</strong> {<FechaEntrega id={orden.id} fecha={orden.fecha} ruta={orden.direccionConsultorio} listarOrdenes={listarOrdenes2} />}
              <br />
              <strong>Doctor:</strong> {orden.nombreDoctor}
              <br />
              <strong>Status:</strong> {estadoMapping[orden.estado] || "Desconocido"}
            </Card.Text>
            <Card.Footer>
              {renderAcciones(orden)}
            </Card.Footer>
          </Card.Body>
        </Card>
      ))}

      <BasicModal
        show={showModal}
        setShow={setShowModal}
        title={titulosModal}
      >
        {contentModal}
      </BasicModal>
    </div>
  );
}

function formatModelOrdenes2(data) {
  return data.map(data => ({
    id: data._id,
    fecha: data.fecha,
    descripcion: data.descripcion,
    estado: data.estado,
  }));
}

function formatModelOrdenes(data) {
  const dataTemp = [];
  data.forEach((data) => {
    dataTemp.push({
      id: data._id,
      folio: data.folio,
      fecha: data.fecha,
      localidad: data.localidad,
      nombreDoctor: data.nombreDoctor,
      nombrePaciente: data.nombrePaciente,
      tipoDentadura: data.tipoDentadura,
      servicios: data.servicios,
      otros: data.otros,
      recibio: data.recibio,
      fechaEntrada: data.fechaEntrada,
      correoDoctor: data.correoDoctor,
      correoConsultorio: data.correoConsultorio,
      estado: data.estado,
      telefonoDoctor: data.telefonoDoctor,
      cancelacion: data.cancelacion,
      cargo: data.cargoOrden,
      cambios: !data.cambios ? "0" : data.cambios,
      aprobado: !data.aprobado ? "No disponible" : data.aprobado,
      direccionConsultorio: data?.direccionConsultorio?.municipio
    });
  });
  return dataTemp;
}

function formatModelServicios(data) {
  return data.map((item) => {
    const dienteArray = item.diente;
    let diente = "";

    if (Array.isArray(dienteArray)) {
      diente = dienteArray
        .map(d => d.match(/\d+/)) // Extract numbers from each string
        .filter(Boolean)          // Remove any null values resulting from no match
        .join(", ");              // Join the numbers with comma and space
    }

    return {
      id: item._id,
      folio: item.folio,
      clasificacion: item.clasificacion,
      material: item.material,
      pieza: item.pieza,
      precio: item.precio,
      descripcionAdicional: item.descripcionAdicional,
      sistemaColor: item.sistemaColor,
      distencion: item.distencion,
      diente: diente,
      tonoInferior: item.tonoInferior,
      tonoMedio: item.tonoMedio,
      tonoSuperior: item.tonoSuperior,
      imagenEsquema: item.imagenEsquema,
      imagenDiente: item.imagenDiente,
    };
  });
}

export default withRouter(CardODS);
