import { useState } from 'react'
import { Form } from 'react-bootstrap'
import { HeaderDash } from '../../components/administrador/Dashboard/Dashboard'
import { MenuDash } from '../../components/administrador/Dashboard/Menu'
import { FooterDash } from '../../components/administrador/Dashboard/Footer'

export const Admin = () => {
  const [busqueda, setBusqueda] = useState();
  return (
    <>
      <div>
        <HeaderDash busqueda={busqueda} setBusqueda={setBusqueda} />
        <MenuDash busqueda={busqueda}/>
      </div>
    </>
  )
}
