import { useState, useEffect } from "react";
import { registraRutas, obtenerFolioRutas } from "../../api/rutas";
import {
  asignarMensajeroOrdenesServicio,
  listarOrdenesServicio,
} from "../../api/ordenesServicio";
import {
  listarRutasPorDia,
} from "../../api/configuracionRutas";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { map } from "lodash";
import { Button, Col, Form, Row, Table } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDeleteLeft } from "@fortawesome/free-solid-svg-icons";
import { getTokenApi, obtenidusuarioLogueado } from "../../api/auth";
import { obtenerUsuario } from "../../api/usuarios";
import { registraEvento } from "../../api/eventos";
import { registraEntregas, obtenerNumeroEntregas } from "../../api/entregas";
import { actualizaDeshabilitarOrdenesServicio } from "../../api/ordenesServicio";
import queryString from "query-string";

export default function AsignacionRuta({ history, setShow, fecha }) {
  //console.log(fecha);
  const [diaSelect, setdiaSelect] = useState(null);
  const [formData, setFormData] = useState(initialFormValue());
  const [dayOfWeek, setDayOfWeek] = useState("");

  useEffect(() => {
    // Fecha fija escrita en el código
    const fixedDate = new Date(formData.fecha);
    const dayIndex = fixedDate.getUTCDay(); // Obtener el índice del día de la semana
    const daysOfWeek = [
      "domingo",
      "lunes",
      "martes",
      "miercoles",
      "jueves",
      "viernes",
      "sabado",
    ];
    const dayName = daysOfWeek[dayIndex];

    // Actualizar el estado con el nombre del día de la semana
    setDayOfWeek(dayName);
  }, [formData.fecha]);

  const [diaRuta, setDiaRuta] = useState("");

  useEffect(() => {
    setDiaRuta(formData.selectRuta.split("/")[2]);
  }, [formData.selectRuta]);

  /******************************************************** */
  const [idRutaSelect, setidRutaSelect] = useState("");
  const [nombreMensajeroRoute, setnombreMensajeroRoute] = useState("");
  const [emailMensajeroRoute, setemailMensajeroRoute] = useState("");
  const [telefonoMensRoute, settelefonoMensRoute] = useState("");
  const [vehiculoModelRoute, setvehiculoModelRoute] = useState("");
  const [vehiculoNERoute, setvehiculoNERoute] = useState("");
  /******************************************************** */

  //console.log(diaRuta);

  //console.log(dayOfWeek);

  const [listarRutas, setListRutas] = useState([]);
  /*
    const obtenerRutas = () => {
        try {
            listarConfiguracionRutas()
                .then((response) => {
                    const { data } = response;

                    if (!listarRutas && data) {
                        setListRutas(formatModelRutas(data));
                    } else {
                        const datosRutas = formatModelRutas(data);
                        setListRutas(datosRutas);
                    }
                })
                .catch((e) => { });
        } catch (e) { }
    };

    useEffect(() => {
        obtenerRutas();
    }, []);*/

  // console.log(listarRutas);

  /* const [listarOrdenes, setListOrdenes] = useState([]);
  
    const obtenerOrdenes = () => {
      try {
        listarOrdenesServicio()
          .then((response) => {
            const { data } = response;
            if (data && Array.isArray(data)) {
              let almacen;
  
              almacen = formatModelOrdenes(data.filter(item => item.direccionConsultorio.toLowerCase() == formData.selectRuta.split("/")[0].toLowerCase()));
              console.log(almacen);
              setListOrdenes(almacen);
            } else {
              let almacen;
              almacen = formatModelOrdenes(data.filter(item => item.direccionConsultorio.toLowerCase() == formData.selectRuta.split("/")[0].toLowerCase()));
              setListOrdenes(almacen);
            }
          })
          .catch((e) => { });
      } catch (e) { }
    };
  
    useEffect(() => {
      obtenerOrdenes();
    }, [formData.selectRuta]);*/

  const [listarOrdenes, setListOrdenes] = useState([]);

  const obtenerOrdenes = () => {
    try {
      listarOrdenesServicio()
        .then((response) => {
          const { data } = response;
          console.log('data', data)
          if (!listarOrdenes && data) {
            setListOrdenes(formatModelOrdenes(data));
          } else {
            const datosOrdenes = formatModelOrdenes(data);
            console.log('datosOrdenes', datosOrdenes)
            const ordenesFilter = datosOrdenes.filter(
              (ordenes) => ordenes.estado === "5" || ordenes.estado === "1"
            );
            console.log('ordenesFilter', ordenesFilter)
            //console.log(ordenesFilter);
            setListOrdenes(ordenesFilter);
          }
        })
        .catch((e) => {});
    } catch (e) {}
  };

  useEffect(() => {
    obtenerOrdenes();
  }, []);

  const [nombreUsuario, setNombreUsuario] = useState("");

  const obtenerDatosUsuario = () => {
    try {
      obtenerUsuario(obtenidusuarioLogueado(getTokenApi()))
        .then((response) => {
          const { data } = response;
          setNombreUsuario(data.nombre);
        })
        .catch((e) => {
          if (e.message === "Network Error") {
            //console.log("No hay internet")
            toast.error("Conexión al servidor no disponible");
          }
        });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    obtenerDatosUsuario();
  }, []);

  const [ordenesFiltradas, setordenesFiltradas] = useState([]);

  useEffect(() => {
    console.log('formData.selectRuta', formData.selectRuta)
    const arregloCiudades = formData.selectRuta
      .split("/")[0]
      .split(",")
      .map((ciudad) =>
        ciudad
          .trim()
          .toLowerCase()
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
      );

    console.log(arregloCiudades);
      console.log('listarOrdenes', listarOrdenes)
    const ordenesFilterDireccion = listarOrdenes.filter((ordenes) => {
      const municipioConsultorio = (ordenes.municipioConsultorio || "")
        .trim()
        .toLowerCase()
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "");
      const cpConsultorio = (ordenes.cpConsultorio || "")
        .trim()
        .toLowerCase()
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "");
      const localidadConsultorio = (ordenes.localidadConsultorio || "")
        .trim()
        .toLowerCase()
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "");
      console.log('localidadConsultorio', localidadConsultorio)
      return (
        arregloCiudades.includes(municipioConsultorio) ||
        arregloCiudades.includes(cpConsultorio) ||
        arregloCiudades.includes(localidadConsultorio)
      );
    });

    //console.log(ordenesFilterDireccion);
    setordenesFiltradas(ordenesFilterDireccion);
  }, [formData.selectRuta]);

  const [arregloIdsOrdenes, setarregloIdsOrdenes] = useState([]);
  const [arregloFoliosOrdenes, setarregloFoliosOrdenes] = useState([]);

  useEffect(() => {
    const idOrdenesFiltradas = ordenesFiltradas.map((orden) => orden.id);
    //console.log("IDs de ordenes filtradas:", idOrdenesFiltradas);
    const foliosOrdenesFiltradas = ordenesFiltradas.map((orden) => orden.folio);
    //console.log("Folios de ordenes filtradas:", foliosOrdenesFiltradas);
    setarregloIdsOrdenes(idOrdenesFiltradas);
    setarregloFoliosOrdenes(foliosOrdenesFiltradas);
  }, [ordenesFiltradas]);

  /* const [listarMensajero, setListMensajero] = useState([]);

  const obtenerMensajeros = () => {
    try {
      listarMensajeros()
        .then((response) => {
          const { data } = response;

          if (!listarMensajero && data) {
            setListMensajero(formatModelMensajero(data));
          } else {
            const datosMensajeros = formatModelMensajero(data);
            setListMensajero(datosMensajeros);
          }
        })
        .catch((e) => {});
    } catch (e) {}
  };

  useEffect(() => {
    obtenerMensajeros();
  }, []);

  const [listarVehiculo, setListVehiculo] = useState([]);

  const obtenerVehiculo = () => {
    try {
      listarVehiculos()
        .then((response) => {
          const { data } = response;

          if (!listarVehiculo && data) {
            setListVehiculo(formatModelVehiculos(data));
          } else {
            const datosVehiculos = formatModelVehiculos(data);
            setListVehiculo(datosVehiculos);
          }
        })
        .catch((e) => {});
    } catch (e) {}
  };

  useEffect(() => {
    obtenerVehiculo();
  }, []);*/

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simula una carga de datos
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, []);

  // Para almacenar el folio actual
  const [folioActual, setFolioActual] = useState("");

  const obtenerFolio = () => {
    try {
      obtenerFolioRutas()
        .then((response) => {
          const { data } = response;
          // console.log(data)
          const { folio } = data;
          setFolioActual(folio);
        })
        .catch((e) => {
          console.log(e);
        });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    obtenerFolio();
  }, []);

  const [folioActualEntrega, setFolioActualEntrega] = useState("");

  const obtenerFolioEntrega = () => {
    try {
      obtenerNumeroEntregas()
        .then((response) => {
          const { data } = response;
          // console.log(data)
          const { noEntrega } = data;
          setFolioActualEntrega(noEntrega);
        })
        .catch((e) => {
          console.log(e);
        });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    obtenerFolioEntrega();
  }, []);

  const actualizarOrdenMensajero = async (idDocumental) => {
    try {
      const dataTemp = {
        mensajero: nombreMensajeroRoute,
        correoMensajero: emailMensajeroRoute,
        telefonoMensajero: telefonoMensRoute,
      };

      const response = await asignarMensajeroOrdenesServicio(
        idDocumental,
        dataTemp
      );
      const { data } = response;
      toast.success(data.mensaje);
    } catch (error) {
      console.error(
        `Error al actualizar la orden del mensajero para el id ${idDocumental}:`,
        error
      );
      setLoading(false);
    }
  };

  let fecha2 = new Date();
  let dia2 = fecha2.getDate(); // Obtener el día (del 1 al 31)
  let mes2 = fecha2.getMonth() + 1; // Obtener el mes (del 0 al 11, por eso se suma 1)
  let anio2 = fecha2.getFullYear(); // Obtener el año (ej. 2023)
  let hora2 = fecha2.getHours(); // Obtener la hora (formato de 0 a 23)
  let minutos2 = fecha2.getMinutes(); // Obtener los minutos

  let datosFecha2 = `${dia2}/${mes2}/${anio2} ${hora2}:${minutos2}`;

  const registraEventos = async (folioOrden) => {
    try {
      const dataTemp5 = {
        fecha: datosFecha2,
        folioOrden: folioOrden,
        usuario: nombreUsuario,
        tipoEvento: "Entrega",
        motivoEvento: "En ruta de entrega",
        estado: "activo",
        leido: "0",
      };

      const response = await registraEvento(dataTemp5);
      const { data } = response;
    } catch (error) {
      console.error(
        `Error al registrar eventos para el folioOrden ${folioOrden}:`,
        error
      );
      setLoading(false);
    }
  };

  const registrarEntregas = async (folioOrden) => {
    try {
      const dataTemp = {
        folio: folioActualEntrega,
        mensajero: nombreMensajeroRoute,
        correoMensajero: emailMensajeroRoute,
        fechayHoraEntrega: "Pendiente",
        ordenTrabajo: folioOrden,
        motivoEntrega: "Pendiente de entrega",
        recibio: "",
        ubicacion: "",
        comentarios: "",
        tipo: "Asignacion ruta",
        estado: "0",
      };

      const response = await registraEntregas(dataTemp);
      const { data } = response;
      console.log(data.mensaje, "de folio ", folioOrden);
    } catch (error) {
      console.error(
        `Error al registrar entregas para el folioOrden ${folioOrden}:`,
        error
      );
      setLoading(false);
    }
  };

  const actualizarOrdenMensajeroIds = async () => {
    for (const idDocumental of arregloIdsOrdenes) {
      await actualizarOrdenMensajero(idDocumental);
    }
    for (const folioOrden of arregloFoliosOrdenes) {
      await registraEventos(folioOrden);
      await registrarEntregas(folioOrden);
    }
  };

  const actualizarEstadoOrdenes = async () => {
    ordenesFiltradas.map(async (cat, index) => {
      const dataTemp = {
        estado: cat.estado == "1" ? "2" : cat.estado == "5" ? "7" : cat.estado,
      };
      actualizaDeshabilitarOrdenesServicio(cat.id, dataTemp);
    });
  };

  const onSubmit = (e) => {
    e.preventDefault();

    if (!formData.selectRuta) {
      toast.warning("Completa el formulario");
    } else {
      try {
        setLoading(true);

        const dataTemp = {
          numeroRuta: folioActual,
          ruta: formData.selectRuta.split("/")[0] + "/" + idRutaSelect,
          nombreMensajero: nombreMensajeroRoute,
          vehiculoAsignado: vehiculoModelRoute,
          numeroEconomico: vehiculoNERoute,
          horaInicio: "",
          observaciones: dayOfWeek,
          fecha: formData.fecha,
          correoMensajero: emailMensajeroRoute,
          ordenesAsignadas: arregloIdsOrdenes,
          estado: "true",
        };
        registraRutas(dataTemp).then((response) => {
          const { data } = response;
          toast.success("Ordenes de cliente asignadas");
          history({
            search: queryString.stringify(""),
        }); 
          actualizarOrdenMensajeroIds();
          actualizarEstadoOrdenes();
          setLoading(false);
          setShow(false);
        });
      } catch (e) {
        console.log(e);
      }
    }
  };

  const [economico, setEconomico] = useState("");

  const obtenerDatos = () => {
    const temp = formData.vehiculoRuta.split("/");
    setEconomico(temp[1]);
  };

  useEffect(() => {
    obtenerDatos();
  }, [formData.vehiculoRuta]);

  const handleDelete = (id) => {
    const updatedOrdenes = ordenesFiltradas.filter((orden) => orden.id !== id);
    setordenesFiltradas(updatedOrdenes);
  };

  //   useEffect(() => {
  //     console.log(formData.nombreMensajeroRuta);
  //   }, [formData.nombreMensajeroRuta]);

  //console.log(ordenesFiltradas);

  const obtenerRutasPorDia = (dia) => {
    try {
      listarRutasPorDia(dia)
        .then((response) => {
          const { data } = response;
          if (data.length > 0) {
            setListRutas(formatModelRutas(data));
          } else {
            setListRutas([]);
          }
        })
        .catch((e) => {});
    } catch (e) {}
  };

  useEffect(() => {
    if (dayOfWeek) {
      obtenerRutasPorDia(dayOfWeek);
    }
  }, [dayOfWeek]);

  useEffect(() => {
    if (formData.selectRuta) {
      const idRutaSelect = formData.selectRuta.split("/")[1];
      const filterRoute = listarRutas.find((data) => data.id === idRutaSelect);
      setidRutaSelect(idRutaSelect);
      setnombreMensajeroRoute(filterRoute?.datosMensajero?.nombre);
      setemailMensajeroRoute(filterRoute?.datosMensajero?.email);
      settelefonoMensRoute(filterRoute?.datosMensajero?.telefono);
      setvehiculoModelRoute(filterRoute?.datosVehiculo?.modeloVehiculo);
      setvehiculoNERoute(filterRoute?.datosVehiculo?.economico);
    }
  }, [formData.selectRuta, listarRutas]);

  return (
    <div>
      <Form id="routeForm" onSubmit={onSubmit} onChange={onChange}>
        <div className="form-group">
          <div className="form-group">
            <Form.Label htmlFor="routeName">
              fecha: <code>*</code>
            </Form.Label>
            <Form.Control
              type="date"
              name="fecha"
              className="form-control"
              defaultValue={formData.fecha}
            />
          </div>
          <div className="form-group">
            <Form.Label htmlFor="routeName">
              Ruta: <code>*</code>
            </Form.Label>
            <Form.Control
              as="select"
              className="form-control"
              id="routeName"
              name="selectRuta"
              defaultValue={formData.selectRuta}
            >
              <option value>Seleccionar</option>
              {map(listarRutas, (cat, index) => (
                <option
                  key={index}
                  value={cat?.nombre + "/" + cat?.id + "/" + cat?.dia}
                  style={{ textTransform: "capitalize" }}
                >
                  {cat?.nombre?.join(" - ")}
                </option>
              ))}
            </Form.Control>
          </div>
          <div className="form-group">
            <Row>
              <Col>
                <Form.Label htmlFor="routeName">
                  Mensajero: <code>*</code>
                </Form.Label>
                <Form.Control
                  type="text"
                  className="form-control"
                  value={nombreMensajeroRoute}
                />
              </Col>
              <Col>
                <Form.Label htmlFor="routeName">
                  Vehiculo: <code>*</code>
                </Form.Label>
                <Form.Control
                  type="text"
                  className="form-control"
                  value={vehiculoModelRoute + " - " + vehiculoNERoute}
                />
              </Col>
            </Row>
          </div>
          <div>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Folio</th>
                  <th>Doctor</th>
                  <th>Domicilio</th>
                  <th>Estado</th>
                  <th>Quitar</th>
                </tr>
              </thead>
              <tbody>
                {ordenesFiltradas.map((orden, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{orden.folio}</td>
                    <td>{orden.nombreDoctor}</td>
                    <td>{orden.localidadConsultorio +", "+ orden.cpConsultorio +", "+ orden.municipioConsultorio}</td>
                    <td>
                      {orden.estado === "1"
                        ? "Para recolectar"
                        : orden.estado === "5"
                        ? "Para entrega"
                        : null}
                    </td>
                    <td>
                      <Button
                        variant="danger"
                        onClick={() => handleDelete(orden.id)}
                      >
                        <FontAwesomeIcon icon={faDeleteLeft} />
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </div>
        <div className="divSubmit">
          <input className="submit" value="Enviar" type="submit" />
        </div>
      </Form>
    </div>
  );
}

function initialFormValue() {
  return {
    status: "",
    nombreSolicitante: "",
    departamento: "",
    observaciones: "",
    vehiculoRuta: "",
    selectRuta: "",
    dia: "",
    fecha: "",
  };
}

function formatModelRutas(data) {
  const dataTemp = [];
  data.forEach((data) => {
    dataTemp.push({
      id: data._id,
      folio: data.folio,
      nombre: data.nombre,
      descripcion: data.descripcion,
      coordenadas: data.coordenadas,
      dia: data.dia,
      datosMensajero: data.datosMensajero,
      datosVehiculo: data.datosVehiculo,
      estado: data.estado,
    });
  });
  return dataTemp;
}

function formatModelMensajero(data) {
  const dataTemp = [];
  data.forEach((data) => {
    dataTemp.push({
      id: data._id,
      nombre: data.nombre,
      telefono: data.telefono,
      email: data.email,
      modeloVehiculo: data.modeloVehiculo,
      placa: data.placa,
      economico: data.economico,
      fecha: data.fecha,
      estado: data.estado,
    });
  });
  return dataTemp;
}

function formatModelVehiculos(data) {
  const dataTemp = [];
  data.forEach((data) => {
    dataTemp.push({
      id: data._id,
      modelo: data.modelo,
      placa: data.placa,
      numEconomico: data.numEconomico,
      estado: data.estado,
    });
  });
  return dataTemp;
}

function formatModelOrdenes(data) {
  const dataTemp = [];
  data.forEach((data) => {
    let municipioConsultorio = "";
    let cpConsultorio = "";
    let localidadConsultorio = "";

    if (typeof data.direccionConsultorio === "object") {
      municipioConsultorio = data.direccionConsultorio.municipio ? data.direccionConsultorio.municipio : "";
      cpConsultorio = data.direccionConsultorio.CP ? data.direccionConsultorio.CP : "";
      localidadConsultorio = data.direccionConsultorio.localidad ? data.direccionConsultorio.localidad : "";
    }

    dataTemp.push({
      id: data._id,
      folio: data.folio,
      fecha: data.fecha,
      localidad: data.localidad,
      nombreDoctor: data.nombreDoctor,
      nombrePaciente: data.nombrePaciente,
      tipoDentadura: data.tipoDentadura,
      servicios: data.servicios,
      otros: data.otros,
      recibio: data.recibio,
      fechaEntrada: data.fechaEntrada,
      correoDoctor: data.correoDoctor,
      correoConsultorio: data.correoConsultorio,
      estado: data.estado,
      telefonoDoctor: data.telefonoDoctor,
      municipioConsultorio: municipioConsultorio,
      cpConsultorio: cpConsultorio,
      localidadConsultorio: localidadConsultorio,
    });
  });
  return dataTemp;
}

