import { useState } from 'react';
import { crearUsuarioOpenpay } from '../../api/openPay';
//var Openpay = require('openpay');
//var openpay = new Openpay('mh3qxtumcdo4u0fuvbg3','sk_bf32c8b3b2d342baace620c67b7f10c2');

function OpenpayIntegration() {
  const [customerData, setCustomerData] = useState({
    name: 'Johnis',
    email: 'johndoesd@example.com',
    last_name: 'Doesd',
    address: {
      city: 'Queretaro',
      state: 'Queretaro',
      line1: 'Calle Morelos no 10',
      line2: 'col. san pablo',
      postal_code: '76000',
      country_code: 'MX',
    },
    phone_number: '44209087658',
    requires_account: true
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCustomerData({ ...customerData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const nuevoCliente = await crearUsuarioOpenpay(customerData);
      console.log('Cliente creado exitosamente:', nuevoCliente);
      // Aquí podrías realizar más acciones, como actualizar el estado de la UI o redirigir a otra página
    } catch (error) {
      console.error('Hubo un error al crear el cliente:', error);
      // Podrías mostrar un mensaje de error al usuario
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <input
        type="text"
        name="name"
        placeholder="Nombre"
        value={customerData.name}
        onChange={handleChange}
      />
      <input
        type="text"
        name="last_name"
        placeholder="Apellido"
        value={customerData.last_name}
        onChange={handleChange}
      />
      <input
        type="email"
        name="email"
        placeholder="Correo electrónico"
        value={customerData.email}
        onChange={handleChange}
      />
      <input
        type="text"
        name="phone_number"
        placeholder="Número de teléfono"
        value={customerData.phone_number}
        onChange={handleChange}
      />
      <input
        type="text"
        name="address_city"
        placeholder="Ciudad"
        value={customerData.address.city}
        onChange={handleChange}
      />
      <input
        type="text"
        name="address_state"
        placeholder="Estado"
        value={customerData.address.state}
        onChange={handleChange}
      />
      <input
        type="text"
        name="address_line1"
        placeholder="Dirección línea 1"
        value={customerData.address.line1}
        onChange={handleChange}
      />
      <input
        type="text"
        name="address_line2"
        placeholder="Dirección línea 2"
        value={customerData.address.line2}
        onChange={handleChange}
      />
      <input
        type="text"
        name="address_postal_code"
        placeholder="Código Postal"
        value={customerData.address.postal_code}
        onChange={handleChange}
      />

      {/* No se muestra el campo country_code ya que está predefinido como "MX" */}
      <button type="submit">Crear Usuario</button>
    </form>
  );
}

export default OpenpayIntegration;
