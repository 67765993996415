import {
  faArrowLeft,
  faArrowRight,
  faCloudArrowUp,
  faFile,
  faFloppyDisk,
  faUserPen,
  faUserPlus,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Button, Col, Form, InputGroup, Row } from "react-bootstrap";
import Dropzone from "../dropzone/Dropzone";
import { subeArchivosCloudinary } from "../../api/cloudinary";
import regimenFiscalData from "../administrador/catalogosJSON/regimenFiscal.json";
import estadoCivilData from "../administrador/catalogosJSON/estadoCivil.json";
import { isCurpValid, isRFCValid } from "../../utils/validations";
import { toast } from "react-toastify";
import {
  actualizarRecursosHumanos,
  registraRecursosHumanos,
} from "../../api/recursosHumanos";
import { listarCargos } from "../../api/cargos";
import { listarDepartamentos } from "../../api/departamentos";
import "./style.css";
import { Load } from "../load/load";
import { LogsInformativos } from "../logs/logs";

const RegistroEmpleados = ({ data, setShow }) => {
  const [currentStep, setCurrentStep] = useState(1);

  console.log(data)

  const nextStep = () => {
    if (currentStep < 4) {
      setCurrentStep(currentStep + 1);
    }
  };

  const prevStep = () => {
    if (currentStep > 1) {
      setCurrentStep(currentStep - 1);
    }
  };

  /* Lógica del componente */

  // Variables
  const [imagenOrden1, setImagenOrden1] = useState(null);
  const [linkImagenEmpleado, setLinkImagenEmpleado] = useState("");
  const [nombreEmpleado, setnombreEmpleado] = useState("");
  const [primerApellido, setPrimerApellido] = useState("");
  const [segundoApellido, setSegundoApellido] = useState("");
  const [fechaNacimiento, setFechaNacimiento] = useState("");
  const [estadoCivil, setEstadoCivil] = useState("No especificado");
  const [curp, setCurp] = useState("");
  const [rfc, setRfc] = useState("");
  const [regimenFiscal, setRegimenFiscal] = useState("");
  const [telefono, setTelefono] = useState("");
  const [email, setEmail] = useState("");
  const [direccion, setDireccion] = useState("");
  const [nombreEmegencia, setNombreEmegencia] = useState("");
  const [telefonoEmergencia, setTelefonoEmergencia] = useState("");
  const [parentezcoContactoEmergencia, setParentezcoContactoEmergencia] = useState("");
  const [porcentajeBeneficiario, setPorcentajeBeneficiario] = useState("");
  const [nombreEmegencia2, setNombreEmegencia2] = useState("");
  const [telefonoEmergencia2, setTelefonoEmergencia2] = useState("");
  const [parentezcoContactoEmergencia2, setParentezcoContactoEmergencia2] = useState("");
  const [porcentajeBeneficiario2, setPorcentajeBeneficiario2] = useState("");
  const [escolaridad, setEscolaridad] = useState("");
  const [seguroMedico, setSeguroMedico] = useState("");
  const [cargo, setCargo] = useState("");
  const [periodo, setPeriodo] = useState("Quincenal");
  const [departamento, setDepartamento] = useState("");
  const [fechaContratacion, setFechaContratacion] = useState("");
  const [fechaTermino, setFechaTermino] = useState("");
  const [salario, setSalario] = useState("");
  const [formaPago, setFormaPago] = useState("");
  const [horarioTrabajo1, setHorarioTrabajo1] = useState("");
  const [horarioTrabajo2, setHorarioTrabajo2] = useState("");
  const [diasDescanso, setDiasDescanso] = useState("");
  const [vacaciones, setVacaciones] = useState("");

  const maxPorcentaje1 = 100 - porcentajeBeneficiario2;
  const maxPorcentaje2 = 100 - porcentajeBeneficiario;

  const escolaridadArr = [
    "Preescolar o primaria",
    "Secundaria",
    "Preparatoria",
    "Carrera Técnica o Comercial",
    "Licenciatura",
    "Maestría",
    "Doctorado",
    "Ninguno",
    "No especificado",
  ];

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simula una carga de datos
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, []);

  const formasPagoArr = ["Efectivo", "Transferencia", "Mixto"];

  const periodosArr = ["Semanal", "Quincenal"];

  const validaCurp = () => {
    if (!isCurpValid(curp)) {
      toast.warning("El CURP no cumple con el formato");
    }
  };

  const validaRFC = () => {
    if (!isRFCValid(rfc)) {
      toast.warning("El RFC no cumple con el formato");
    }
  };

  const [listCargos, setListCargos] = useState([]);

  const obtenerCargos = () => {
    try {
      listarCargos()
        .then((response) => {
          const { data } = response;

          if (!listCargos && data) {
            setListCargos(formatModelCargos(data));
          } else {
            const datosCargos = formatModelCargos(data);
            setListCargos(datosCargos);
          }
        })
        .catch((e) => {});
    } catch (e) {}
  };

  useEffect(() => {
    obtenerCargos();
  }, []);

  const [listarDepartamento, setListDepartamento] = useState([]);

  const obtenerDepartamento = () => {
    try {
      listarDepartamentos()
        .then((response) => {
          const { data } = response;

          if (!listarDepartamento && data) {
            setListDepartamento(formatModelDepartamento(data));
          } else {
            const datosDepartamento = formatModelDepartamento(data);
            setListDepartamento(datosDepartamento);
          }
        })
        .catch((e) => { });
    } catch (e) { }
  };

  useEffect(() => {
    obtenerDepartamento();
  }, []);

  useEffect(() => {
    if (data) {
      setLoading(true);
      setnombreEmpleado(data[1]);
      setFechaNacimiento(data[2]);
      setEstadoCivil(data[6]);
      setCurp(data[20]);
      setRfc(data[19]);
      setRegimenFiscal(data[21]);
      setTelefono(data[4]);
      setEmail(data[5]);
      setDireccion(data[3]);
      setNombreEmegencia(data[14].split("/")[0]);
      setTelefonoEmergencia(data[14].split("/")[1]);
      setParentezcoContactoEmergencia(data[14].split("/")[2]);
      setPorcentajeBeneficiario(data[14].split("/")[3]);
      setNombreEmegencia(data[24].split("/")[0]);
      setTelefonoEmergencia(data[24].split("/")[1]);
      setParentezcoContactoEmergencia(data[24].split("/")[2]);
      setPorcentajeBeneficiario(data[24].split("/")[3]);
      setEscolaridad(data[16]);
      setSeguroMedico(data[15]);
      setCargo(data[7]);
      setFechaContratacion(data[8]);
      setFechaTermino(data[23]);
      setSalario(data[9]);
      setFormaPago(data[10]);
      setHorarioTrabajo1(data[11].split("/")[0]);
      setHorarioTrabajo2(data[11].split("/")[1]);
      setDiasDescanso(data[12]);
      setVacaciones(data[13]);
    }
  }, [data]);

  const cargarImagenEmpleado = () => {
    try {
      subeArchivosCloudinary(imagenOrden1, "laboratorio")
        .then((response) => {
          const { data } = response;
          console.log(data);
          const { secure_url } = data;
          setLinkImagenEmpleado(secure_url);
          setLoading(false);
        })
        .catch((e) => {
          console.log(e);
        });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    cargarImagenEmpleado();
  }, [imagenOrden1]);

  const registraEmpleado = (e) => {
    e.preventDefault();

    if (
      !nombreEmpleado ||
      !primerApellido ||
      !isCurpValid(curp) ||
      !isRFCValid(rfc) ||
      !telefono ||
      !direccion ||
      !nombreEmegencia ||
      !telefonoEmergencia
    ) {
      toast.warning("Datos incompletos");
    } else {
      try {
        setLoading(true);
        const dataTemp = {
          nombreCompleto:
            nombreEmpleado + " " + primerApellido + " " + segundoApellido,
          fechaNacimiento: fechaNacimiento,
          direccion: direccion,
          telefono: telefono,
          email: email,
          estadoCivil: estadoCivil,
          regimenFiscal: regimenFiscal,
          curp: curp,
          rfc: rfc,
          cargo: cargo,
          fechaContratacion: fechaContratacion,
          salario: salario,
          formaPago: formaPago,
          horarioLaboral: {
            horaEntrada: horarioTrabajo1,
            horaSalida: horarioTrabajo2,
          },
          diasDescanso: diasDescanso,
          vacaciones: vacaciones,
          contactoEmergencia: nombreEmegencia + " / " + telefonoEmergencia + " / " + parentezcoContactoEmergencia + " / " + porcentajeBeneficiario,
          contactoEmergencia2: nombreEmegencia2 + " / " + telefonoEmergencia2 + " / " + parentezcoContactoEmergencia2 + " / " + porcentajeBeneficiario2,
          seguroMedico: seguroMedico,
          nivelEducacion: escolaridad,
          foto: linkImagenEmpleado,
          estado: "true",
          departamento: departamento,
          periodo: periodo,
          fechaTermino: fechaTermino
        };
        registraRecursosHumanos(dataTemp).then((response) => {
          const { data } = response;
          LogsInformativos("Se ha registrado el empleado " + dataTemp.nombreCompleto, dataTemp)
          toast.success("Registro exitoso del empleado");
          setLoading(false);
        });
      } catch (e) {
        console.log(e);
      }
    }
  };

  const actualizaEmpleado = (e) => {
    e.preventDefault();
    try {
      const dataTemp = {
        direccion,
        telefono: telefono,
        email: email,
        estadoCivil: estadoCivil,
        cargo: cargo,
        fechaContratacion: fechaContratacion,
        salario: salario,
        formaPago: formaPago,
        horarioLaboral: {
          horaEntrada: horarioTrabajo1,
          horaSalida: horarioTrabajo2,
        },
        diasDescanso: diasDescanso,
        vacaciones: vacaciones,
        contactoEmergencia: nombreEmegencia + " / " + telefonoEmergencia + " / " + parentezcoContactoEmergencia + " / " + porcentajeBeneficiario,
        contactoEmergencia2: nombreEmegencia2 + " / " + telefonoEmergencia2 + " / " + parentezcoContactoEmergencia2 + " / " + porcentajeBeneficiario2,

        seguroMedico: seguroMedico,
        nivelEducacion: escolaridad,
        regimenFiscal: regimenFiscal,
        foto: linkImagenEmpleado,
        periodo: periodo,
        fechaTermino: fechaTermino
      };

      actualizarRecursosHumanos(data[0], dataTemp).then((response) => {
        const { data } = response;
        console.log(data);
        LogsInformativos("Se ha registrado el empleado " + dataTemp.seguroMedico, dataTemp)
        toast.success("Datos del empleado actualizados");
        setShow(false);
        //cancelarRegistro()
      });
    } catch (e) {
      console.log(e);
    }
  };

  /* Fin lógica */

  return (
    <>
    {loading && <Load />}
    <div className="contenedorPrincipalOrden">
      <div className="containerOrden">
        <div className="containerDatosOS">
          <h1 className="ordenTituloP">
            {!data ? "Registro de empleados" : "Modificar datos de empleado"}
          </h1>
          <div className="barraProgreso">
            {[1, 2, 3, 4].map((stepNum) => (
              <div
                key={stepNum}
                className={`step ${stepNum <= currentStep ? "active" : ""} `}
              >
                {stepNum}
                <div>
                  {stepNum === 1 ? (
                    <>
                      <span className="textocontaineret">Datos Personales</span>
                    </>
                  ) : stepNum === 2 ? (
                    <>
                      <span className="textocontaineret">
                        Datos de contacto
                      </span>
                    </>
                  ) : stepNum === 3 ? (
                    <>
                      <span className="textocontaineret">
                        Escolaridad y Laboral
                      </span>
                    </>
                  ) : stepNum === 4 ? (
                    <>
                      <span className="textocontaineret">Fotografía</span>
                    </>
                  ) : null}
                </div>
              </div>
            ))}
          </div>
          {currentStep === 1 && (
            <div>
              <h2>Datos personales del empleado</h2>
              <hr />
              <div>
                <Row className="mb-2 mb-md-4 mb-lg-7">
                  <Col
                    sm={12}
                    md={4}
                    lg={4}
                    className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
                  >
                    <Form.Label>
                      {!data ? "Nombre (s):" : "Nombre Completo:"} <code>*</code>
                    </Form.Label>
                  </Col>
                  <Col sm={12} md={8} lg={8}>
                    <Form.Control
                      type="text"
                      placeholder="Ingresa nombre(s) del empleado"
                      value={nombreEmpleado}
                      onChange={(e) => setnombreEmpleado(e.target.value)}
                      disabled={!data ? false : true}
                    />
                  </Col>
                </Row>
                <Row
                  className={!data ? "mb-2 mb-md-4 mb-lg-7" : "modificaUsuario"}
                >
                  <Col
                    sm={12}
                    md={4}
                    lg={4}
                    className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
                  >
                    <Form.Label>Primer Apellido: <code>*</code></Form.Label>
                  </Col>
                  <Col sm={12} md={8} lg={8}>
                    <Form.Control
                      type="text"
                      placeholder="Ingresa primer apellido del empleado"
                      value={primerApellido}
                      onChange={(e) => setPrimerApellido(e.target.value)}
                      disabled={!data ? false : true}
                    />
                  </Col>
                </Row>
                <Row
                  className={!data ? "mb-2 mb-md-4 mb-lg-7" : "modificaUsuario"}
                >
                  <Col
                    sm={12}
                    md={4}
                    lg={4}
                    className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
                  >
                    <Form.Label>Segundo Apellido:</Form.Label>
                  </Col>
                  <Col sm={12} md={8} lg={8}>
                    <Form.Control
                      type="text"
                      placeholder="Ingresa segundo apellido del empleado"
                      value={segundoApellido}
                      onChange={(e) => setSegundoApellido(e.target.value)}
                      disabled={!data ? false : true}
                    />
                  </Col>
                </Row>
                <Row className="mb-2 mb-md-4 mb-lg-7">
                  <Col
                    sm={12}
                    md={4}
                    lg={4}
                    className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
                  >
                    <Form.Label>Fecha de nacimiento:</Form.Label>
                  </Col>
                  <Col sm={12} md={8} lg={8}>
                    {!data ? (
                      <Form.Control
                        type="date"
                        value={fechaNacimiento}
                        onChange={(e) => setFechaNacimiento(e.target.value)}
                      />
                    ) : (
                      <Form.Control
                        type="text"
                        value={fechaNacimiento}
                        disabled
                      />
                    )}
                  </Col>
                </Row>
                <Row className="mb-2 mb-md-4 mb-lg-7">
                  <Col
                    sm={12}
                    md={4}
                    lg={4}
                    className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
                  >
                    <Form.Label>Estado Civil:</Form.Label>
                  </Col>
                  <Col sm={12} md={8} lg={8}>
                    <Form.Control
                      as="select"
                      value={estadoCivil}
                      onChange={(e) => setEstadoCivil(e.target.value)}
                    >
                      <option value="No especificado">No especificado</option>
                      {estadoCivilData.map((estado) => (
                        <option key={estado.código} value={estado.nombre}>
                          {estado.nombre}
                        </option>
                      ))}
                    </Form.Control>
                  </Col>
                </Row>
                <Row className="mb-2 mb-md-4 mb-lg-7">
                  <Col
                    sm={12}
                    md={4}
                    lg={4}
                    className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
                  >
                    <Form.Label>CURP: <code>*</code></Form.Label>
                  </Col>
                  <Col sm={12} md={8} lg={8}>
                    <Form.Control
                      type="text"
                      placeholder="Ingresa los 18 caracteres del CURP"
                      value={curp}
                      onChange={(e) => setCurp(e.target.value.toUpperCase())}
                      onBlur={validaCurp}
                      disabled={!data ? false : true}
                    />
                  </Col>
                </Row>
                <Row className="mb-2 mb-md-4 mb-lg-7">
                  <Col
                    sm={12}
                    md={4}
                    lg={4}
                    className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
                  >
                    <Form.Label>RFC: <code>*</code></Form.Label>
                  </Col>
                  <Col sm={12} md={8} lg={8}>
                    <Form.Control
                      type="text"
                      placeholder="Ingresa los 13 caracteres del RFC"
                      value={rfc}
                      onChange={(e) => setRfc(e.target.value.toUpperCase())}
                      onBlur={validaRFC}
                      disabled={!data ? false : true}
                    />
                  </Col>
                </Row>
                <Row className="mb-2 mb-md-4 mb-lg-7">
                  <Col
                    sm={12}
                    md={4}
                    lg={4}
                    className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
                  >
                    <Form.Label>Régimen Fiscal: <code>*</code></Form.Label>
                  </Col>
                  <Col sm={12} md={8} lg={8}>
                    <Form.Control
                      as="select"
                      value={regimenFiscal}
                      onChange={(e) => setRegimenFiscal(e.target.value)}
                    >
                      <option value="">Seleccionar</option>
                      {regimenFiscalData.map((regimen) => (
                        <option
                          key={regimen.id}
                          value={regimen.id + " - " + regimen.descripcion}
                        >
                          {regimen.id + " - " + regimen.descripcion}
                        </option>
                      ))}
                    </Form.Control>
                  </Col>
                </Row>
              </div>
            </div>
          )}

          {currentStep === 2 && (
            <div>
              <h2>Datos de contacto del empleado</h2>
              <hr />
              <div>
                <Row className="mb-2 mb-md-4 mb-lg-7">
                  <Col
                    sm={12}
                    md={4}
                    lg={4}
                    className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
                  >
                    <Form.Label>Teléfono: <code>*</code></Form.Label>
                  </Col>
                  <Col sm={12} md={8} lg={8}>
                    <Form.Control
                      type="text"
                      placeholder="Ingresa el teléfono a 10 caracteres"
                      value={telefono}
                      onChange={(e) => {
                        let inputValue = e.target.value;
                        if (
                          /^\d*$/.test(inputValue) &&
                          inputValue.length <= 10
                        ) {
                          setTelefono(inputValue);
                        }
                      }}
                    />
                  </Col>
                </Row>
                <Row className="mb-2 mb-md-4 mb-lg-7">
                  <Col
                    sm={12}
                    md={4}
                    lg={4}
                    className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
                  >
                    <Form.Label>Correo electrónico: <code>*</code></Form.Label>
                  </Col>
                  <Col sm={12} md={8} lg={8}>
                    <Form.Control
                      type="email"
                      placeholder="ejemplo@ejemplo.com"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </Col>
                </Row>
                <Row className="mb-2 mb-md-4 mb-lg-7">
                  <Col
                    sm={12}
                    md={4}
                    lg={4}
                    className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
                  >
                    <Form.Label>Dirección: <code>*</code></Form.Label>
                  </Col>
                  <Col sm={12} md={8} lg={8}>
                    <Form.Control
                      type="text"
                      placeholder="Ingresa la dirección completa"
                      value={direccion}
                      onChange={(e) => setDireccion(e.target.value)}
                    />
                  </Col>
                </Row>
                <h4 style={{ textAlign: "center" }}>Beneficiario #1</h4>
                <hr />
                <Row className="mb-2 mb-md-4 mb-lg-7">
                  <Col
                    sm={12}
                    md={4}
                    lg={4}
                    className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
                  >
                    <Form.Label>Nombre Completo: <code>*</code></Form.Label>
                  </Col>
                  <Col sm={12} md={8} lg={8}>
                    <Form.Control
                      type="text"
                      placeholder="Ingresa el nombre del contacto en caso de emergencia"
                      value={nombreEmegencia}
                      onChange={(e) => setNombreEmegencia(e.target.value)}
                    />
                  </Col>
                </Row>
                <Row className="mb-2 mb-md-4 mb-lg-7">
                  <Col
                    sm={12}
                    md={4}
                    lg={4}
                    className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
                  >
                    <Form.Label>Teléfono: <code>*</code></Form.Label>
                  </Col>
                  <Col sm={12} md={8} lg={8}>
                    <Form.Control
                      type="tel"
                      placeholder="Ingresa el teléfono de emergencia a 10 caracteres"
                      value={telefonoEmergencia}
                      onChange={(e) => {
                        let inputValue = e.target.value;
                        if (
                          /^\d*$/.test(inputValue) &&
                          inputValue.length <= 10
                        ) {
                          setTelefonoEmergencia(inputValue);
                        }
                      }}
                    />
                  </Col>
                </Row>
                <Row className="mb-2 mb-md-4 mb-lg-7">
                  <Col
                    sm={12}
                    md={4}
                    lg={4}
                    className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
                  >
                    <Form.Label>Parentezco: <code>*</code></Form.Label>
                  </Col>
                  <Col sm={12} md={8} lg={8}>
                    <Form.Control
                      type="text"
                      placeholder="Ingresa el parentezco que tienes con el contacto en caso de emergencia"
                      value={parentezcoContactoEmergencia}
                      onChange={(e) => setParentezcoContactoEmergencia(e.target.value)}
                    />
                  </Col>
                </Row>
                <Row className="mb-2 mb-md-4 mb-lg-7">
                  <Col
                    sm={12}
                    md={4}
                    lg={4}
                    className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
                  >
                    <Form.Label>Porcentaje: <code>*</code></Form.Label>
                  </Col>
                  <Col sm={12} md={8} lg={8}>
                    <Form.Control
                      type="text"
                      placeholder="Ingresa el porcentaje de este beneficiario"
                      value={porcentajeBeneficiario}
                      onChange={(e) => {
                        let value = parseInt(e.target.value, 10);
                        if (value >= 0 && value <= maxPorcentaje1) {
                          setPorcentajeBeneficiario(value);
                        }
                      }}
                    />
                  </Col>
                </Row>

                <h4 style={{ textAlign: "center" }}>Beneficiario #2</h4>
                <hr />
                <Row className="mb-2 mb-md-4 mb-lg-7">
                  <Col
                    sm={12}
                    md={4}
                    lg={4}
                    className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
                  >
                    <Form.Label>Nombre Completo:</Form.Label>
                  </Col>
                  <Col sm={12} md={8} lg={8}>
                    <Form.Control
                      type="text"
                      placeholder="Ingresa el nombre del contacto en caso de emergencia"
                      value={nombreEmegencia2}
                      onChange={(e) => setNombreEmegencia2(e.target.value)}
                    />
                  </Col>
                </Row>
                <Row className="mb-2 mb-md-4 mb-lg-7">
                  <Col
                    sm={12}
                    md={4}
                    lg={4}
                    className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
                  >
                    <Form.Label>Teléfono:</Form.Label>
                  </Col>
                  <Col sm={12} md={8} lg={8}>
                    <Form.Control
                      type="tel"
                      placeholder="Ingresa el teléfono de emergencia a 10 caracteres"
                      value={telefonoEmergencia2}
                      onChange={(e) => {
                        let inputValue = e.target.value;
                        if (
                          /^\d*$/.test(inputValue) &&
                          inputValue.length <= 10
                        ) {
                          setTelefonoEmergencia2(inputValue);
                        }
                      }}
                    />
                  </Col>
                </Row>
                <Row className="mb-2 mb-md-4 mb-lg-7">
                  <Col
                    sm={12}
                    md={4}
                    lg={4}
                    className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
                  >
                    <Form.Label>Parentezco:</Form.Label>
                  </Col>
                  <Col sm={12} md={8} lg={8}>
                    <Form.Control
                      type="text"
                      placeholder="Ingresa el parentezco que tienes con el contacto en caso de emergencia"
                      value={parentezcoContactoEmergencia2}
                      onChange={(e) => setParentezcoContactoEmergencia2(e.target.value)}
                    />
                  </Col>
                </Row>
                <Row className="mb-2 mb-md-4 mb-lg-7">
                  <Col
                    sm={12}
                    md={4}
                    lg={4}
                    className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
                  >
                    <Form.Label>Porcentaje:</Form.Label>
                  </Col>
                  <Col sm={12} md={8} lg={8}>
                    <Form.Control
                      type="text"
                      placeholder="Ingresa el porcentaje de este beneficiario"
                      value={porcentajeBeneficiario2}
                      onChange={(e) => {
                        let value = parseInt(e.target.value, 10);
                        if (value >= 0 && value <= maxPorcentaje2) {
                          setPorcentajeBeneficiario2(value);
                        }
                      }}
                    />
                  </Col>
                </Row>
              </div>
            </div>
          )}

          {currentStep === 3 && (
            <div>
              <h2>Datos de escolaridad y laborales</h2>
              <hr />
              <div>
                <Row className="mb-2 mb-md-4 mb-lg-7">
                  <Col
                    sm={12}
                    md={4}
                    lg={4}
                    className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
                  >
                    <Form.Label>Nivel de escolaridad:</Form.Label>
                  </Col>
                  <Col sm={12} md={8} lg={8}>
                    <Form.Control
                      as="select"
                      value={escolaridad}
                      onChange={(e) => setEscolaridad(e.target.value)}
                    >
                      <option value="">Seleccionar</option>
                      {escolaridadArr.map((escolaridad, index) => (
                        <option key={index} value={escolaridad}>
                          {escolaridad}
                        </option>
                      ))}
                    </Form.Control>
                  </Col>
                </Row>
                <h4 style={{ textAlign: "center" }}>Laboral</h4>
                <hr />
                <Row className="mb-2 mb-md-4 mb-lg-7">
                  <Col
                    sm={12}
                    md={4}
                    lg={4}
                    className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
                  >
                    <Form.Label>Seguro médico:</Form.Label>
                  </Col>
                  <Col sm={12} md={8} lg={8}>
                    <Form.Control
                      type="text"
                      placeholder="Ingresa el seguro médico con el que cuenta"
                      value={seguroMedico}
                      onChange={(e) => setSeguroMedico(e.target.value)}
                      disabled={!data ? false : true}
                    />
                  </Col>
                </Row>
                <Row className="mb-2 mb-md-4 mb-lg-7">
                  <Col
                    sm={12}
                    md={4}
                    lg={4}
                    className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
                  >
                    <Form.Label>Cargo: <code>*</code></Form.Label>
                  </Col>
                  <Col sm={12} md={8} lg={8}>
                    <Form.Control
                      as="select"
                      value={cargo}
                      onChange={(e) => setCargo(e.target.value)}
                    >
                      <option value="">Seleccionar cargo</option>
                      {listCargos.map((cargo, index) => (
                        <option value={cargo.nombre}>{cargo.nombre}</option>
                      ))}
                    </Form.Control>
                  </Col>
                </Row>
                <Row className="mb-2 mb-md-4 mb-lg-7">
                  <Col
                    sm={12}
                    md={4}
                    lg={4}
                    className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
                  >
                    <Form.Label>Departamento: <code>*</code></Form.Label>
                  </Col>
                  <Col sm={12} md={8} lg={8}>
                    <Form.Control
                      as="select"
                      value={departamento}
                      onChange={(e) => setDepartamento(e.target.value)}
                    >
                      <option value="">Seleccionar departamento</option>
                      {listarDepartamento.map((departamento, index) => (
                        <option value={departamento.nombre}>{departamento.nombre}</option>
                      ))}
                    </Form.Control>
                  </Col>
                </Row>
                <Row className="mb-2 mb-md-4 mb-lg-7">
                  <Col
                    sm={12}
                    md={4}
                    lg={4}
                    className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
                  >
                    <Form.Label>Fecha de contratación: <code>*</code></Form.Label>
                  </Col>
                  <Col sm={12} md={8} lg={8}>
                    <Form.Control
                      type="date"
                      value={fechaContratacion}
                      onChange={(e) => setFechaContratacion(e.target.value)}
                    />
                  </Col>
                </Row>
                <Row className="mb-2 mb-md-4 mb-lg-7">
                  <Col
                    sm={12}
                    md={4}
                    lg={4}
                    className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
                  >
                    <Form.Label>Fecha de termino del contrato: <code>*</code></Form.Label>
                  </Col>
                  <Col sm={12} md={8} lg={8}>
                    <Form.Control
                      type="date"
                      value={fechaTermino}
                      onChange={(e) => setFechaTermino(e.target.value)}
                    />
                  </Col>
                </Row>
                <Row className="mb-2 mb-md-4 mb-lg-7">
                  <Col
                    sm={12}
                    md={4}
                    lg={4}
                    className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
                  >
                    <Form.Label>Periodo: <code>*</code></Form.Label>
                  </Col>
                  <Col sm={12} md={8} lg={8}>
                    <Form.Control
                      as="select"
                      disabled
                      value={periodo}
                      onChange={(e) => setPeriodo(e.target.value)}
                    >
                      <option value="">Seleccionar periodo</option>
                      {periodosArr.map((periodo, index) => (
                        <option value={periodo}>{periodo}</option>
                      ))}
                    </Form.Control>
                  </Col>
                </Row>
                <Row className="mb-2 mb-md-4 mb-lg-7">
                  <Col
                    sm={12}
                    md={4}
                    lg={4}
                    className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
                  >
                    <Form.Label>Salario por dia: <code>*</code></Form.Label>
                  </Col>
                  <Col sm={12} md={8} lg={8}>
                    <InputGroup>
                      <InputGroup.Text>$</InputGroup.Text>
                      <Form.Control
                        type="text"
                        placeholder="Ingresa el salario percibido"
                        value={salario}
                        onChange={(e) => {
                          let inputValue = e.target.value;
                          if (/^\d*\.?\d*$/.test(inputValue)) {
                            setSalario(inputValue);
                          }
                        }}
                      />
                    </InputGroup>
                  </Col>
                </Row>
                <Row className="mb-2 mb-md-4 mb-lg-7">
                  <Col
                    sm={12}
                    md={4}
                    lg={4}
                    className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
                  >
                    <Form.Label>Forma de pago: <code>*</code></Form.Label>
                  </Col>
                  <Col sm={12} md={8} lg={8}>
                    <Form.Control
                      as="select"
                      value={formaPago}
                      onChange={(e) => setFormaPago(e.target.value)}
                    >
                      <option value="">Seleccionar</option>
                      {formasPagoArr.map((forma, index) => (
                        <option value={forma} key={index}>
                          {forma}
                        </option>
                      ))}
                    </Form.Control>
                  </Col>
                </Row>
                <Row className="mb-2 mb-md-4 mb-lg-7">
                  <Col
                    sm={12}
                    md={4}
                    lg={4}
                    className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
                  >
                    <Form.Label>Horario de trabajo: <code>*</code></Form.Label>
                  </Col>
                  <Col
                    sm={12}
                    md={8}
                    lg={8}
                    style={{ display: "flex", alignItems: "end" }}
                  >
                    <Col
                      sm={12}
                      md={6}
                      lg={6}
                      style={{ display: "flex", alignItems: "end" }}
                    >
                      <Form.Label>De:</Form.Label>
                      <Form.Control
                        type="time"
                        value={horarioTrabajo1}
                        onChange={(e) => setHorarioTrabajo1(e.target.value)}
                      />
                    </Col>
                    <Col
                      sm={12}
                      md={6}
                      lg={6}
                      style={{ display: "flex", alignItems: "end" }}
                    >
                      <Form.Label>A:</Form.Label>
                      <Form.Control
                        type="time"
                        value={horarioTrabajo2}
                        onChange={(e) => setHorarioTrabajo2(e.target.value)}
                      />
                    </Col>
                  </Col>
                </Row>
                <Row className="mb-2 mb-md-4 mb-lg-7">
                  <Col
                    sm={12}
                    md={4}
                    lg={4}
                    className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
                  >
                    <Form.Label>Días de descanso:</Form.Label>
                  </Col>
                  <Col sm={12} md={8} lg={8}>
                    <Form.Control
                      type="number"
                      placeholder="Ingresa los días de descanso"
                      value={diasDescanso}
                      onChange={(e) => setDiasDescanso(e.target.value)}
                    />
                  </Col>
                </Row>
                <Row className="mb-2 mb-md-4 mb-lg-7">
                  <Col
                    sm={12}
                    md={4}
                    lg={4}
                    className="d-flex align-items-end justify-content-start justify-content-md-end justify-content-lg-end"
                  >
                    <Form.Label>Vacaciones:</Form.Label>
                  </Col>
                  <Col sm={12} md={8} lg={8}>
                    <Form.Control
                      type="number"
                      placeholder="Ingresa el número de dias de vacaciones"
                      value={vacaciones}
                      onChange={(e) => setVacaciones(e.target.value)}
                    />
                  </Col>
                </Row>
              </div>
            </div>
          )}

          {currentStep === 4 && (
            <div>
              <h2>Subir fotografía reciente del empleado</h2>
              <hr />
              <div style={{ textAlign: "center" }}>
                <Dropzone setImagenFile={setImagenOrden1} />
                <p>Cargar fotografía</p>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="buttonsOS">
        <div className="botonAntOS">
          {currentStep > 1 && currentStep <= 4 && (
            <Button onClick={prevStep} variant="warning">
              <FontAwesomeIcon icon={faArrowLeft} />
              &nbsp;Anterior
            </Button>
          )}
        </div>
        <div className="botonSigOS">
          {currentStep < 4 ? (
            // Renderizar el botón "Siguiente" en otros casos
            <Button onClick={nextStep}>
              Siguiente <FontAwesomeIcon icon={faArrowRight} />
            </Button>
          ) : (
            currentStep === 4 &&
            (!data ? (
              <Button variant="success" onClick={registraEmpleado}>
                <FontAwesomeIcon icon={faUserPlus} /> Registrar
              </Button>
            ) : (
              <Button onClick={actualizaEmpleado}>
                <FontAwesomeIcon icon={faUserPen} /> Actualizar
              </Button>
            ))
          )}
        </div>
      </div>
    </div>
    </>
  );
};

function formatModelCargos(data) {
  const dataTemp = [];
  data.forEach((data) => {
    dataTemp.push({
      id: data._id,
      nombre: data.nombre,
    });
  });
  return dataTemp;
}

function formatModelDepartamento(data) {
  const dataTemp = [];
  data.forEach((data) => {
    dataTemp.push({
      id: data._id,
      folio: data.folio,
      nombre: data.nombre,
      estado: data.estado,
    });
  });
  return dataTemp;
}

export default RegistroEmpleados;
